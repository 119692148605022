import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Message, AgentResponseBlock, AgentMessage } from '@/agentChatApi';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { BarChart2, Database, Search, Wrench } from 'lucide-react';
import {
  Tooltip as UITooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

const COLORS = ["#223065", "#4558A5", "#7080C2", "#B3BDE7", "#DBE0F5"];

// Split into separate components
const MarkdownBlock: React.FC<{ markdown: string }> = ({ markdown }) => (
  <div className="markdown-content">
    <ReactMarkdown>{markdown}</ReactMarkdown>
  </div>
);

const TaxonomyNodeLink: React.FC<{ node_id: string; display_text: string }> = ({ 
  node_id, 
  display_text 
}) => (
  <button 
    className="text-blue-500 hover:underline" 
    onClick={() => console.log('Navigate to node:', node_id)}
  >
    {display_text}
  </button>
);

const PieChartBlock: React.FC<{ 
  data: Record<string, number>; 
  title: string 
}> = ({ data, title }) => {
  // Sort data by value in descending order
  const sortedData = Object.entries(data)
    .map(([name, value]) => ({
      name,
      value: Number(value)
    }))
    .sort((a, b) => b.value - a.value);

  return (
    <div className="p-4 rounded bg-white">
      <h3 className="font-bold mb-4">{title}</h3>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={sortedData}
              dataKey="value"
              nameKey="name"
              animationBegin={0}
              animationDuration={250}
              labelLine={false}
              label={(entry) => `${entry.name} (${entry.value.toFixed(1)})`}
            >
              {sortedData.map((_, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value: number) => value.toFixed(1)} />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const BlockRenderer: React.FC<{ block: AgentResponseBlock }> = ({ block }) => {
  switch (block.type) {
    case 'markdown':
      return <MarkdownBlock markdown={block.markdown} />;
      
    case 'taxonomy_node_link':
      return (
        <TaxonomyNodeLink 
          node_id={block.node_id} 
          display_text={block.display_text} 
        />
      );
      
    case 'pie_chart':
      return <PieChartBlock data={block.data} title={block.title} />;
      
    default:
      console.warn('Unknown block type:', block);
      return null;
  }
};

const ToolIcon: React.FC<{ tool_name: string }> = ({ tool_name }) => {
  const getIcon = () => {
    switch (tool_name) {
      case 'run_sql':
        return <Database className="w-4 h-4" />;
      case 'deep_conversation_analysis':
        return <BarChart2 className="w-4 h-4" />;
      case 'vector_search':
        return <Search className="w-4 h-4" />;
      default:
        return <Wrench className="w-4 h-4" />;
    }
  };

  const getPrettyName = () => {
    switch (tool_name) {
      case 'run_sql':
        return 'SQL Query';
      case 'deep_conversation_analysis':
        return 'Deep Conversation Analysis';
      case 'vector_search':
        return 'Vector Search';
      default:
        return 'Tool Operation';
    }
  };

  return (
    <TooltipProvider>
      <UITooltip>
        <TooltipTrigger>
          <div className="text-gray-600">
            {getIcon()}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{getPrettyName()}</p>
        </TooltipContent>
      </UITooltip>
    </TooltipProvider>
  );
};

const MessageContent: React.FC<{ message: Message }> = ({ message }) => {
  if (message.role === 'user') {
    return <p>{message.content}</p>;
  }
  
  if (message.role === 'tool_use_event') {
    const toolEvent = message.content;
    return (
      <div className="text-sm flex items-center gap-2">
        <ToolIcon tool_name={toolEvent.tool_name} />
        <span>{toolEvent.reasoning}</span>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {(message as AgentMessage).content.map((block: AgentResponseBlock, index: number) => (
        <BlockRenderer key={index} block={block} />
      ))}
    </div>
  );
};

export const MessageComponent: React.FC<{ message: Message }> = ({ message }) => {
  if (message.role === 'tool_use_event') {
    return (
      <div className="flex justify-center mb-2">
        <div className="text-gray-500 px-4 py-2">
          <MessageContent message={message} />
        </div>
      </div>
    );
  }

  return (
    <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-2`}>
      <div
        className={`rounded-lg px-6 py-4 max-w-5xl shadow-md ${
          message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-white text-gray-900'
        }`}
      >
        <MessageContent message={message} />
      </div>
    </div>
  );
};
