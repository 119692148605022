import React, { useState } from "react";

import { SelectOutlined } from "@ant-design/icons";
import {
  Tooltip as AntTooltip,
  Col,
  Divider,
  Row,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import { ColumnGroupType } from "antd/lib/table";
import CheckableTag from "antd/lib/tag/CheckableTag";
import moment from "moment";
import { ConversationMetadata } from "../../../indexTypes";
import { renderCrmUrl } from "../../../utils";
import { ConversationModal } from "../../../components/ConversationModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";

export const ExampleConversationsTable: React.FC<{ conversations: ConversationMetadata[] }> = ({
  conversations,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [conversationMetadata, setConversationMetadata] = useState<
    ConversationMetadata | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.dashboard);
  const dashboardContext = { state, dispatch };

  const defaultSelectedMetadata =
    dashboardContext.state.viewState.data?.view.settings.defaultSelectedMetadata;
  const [metadataColumnsVisible, setMetadataColumnsVisible] = useState<string[]>(
    defaultSelectedMetadata ?? []
  );

  const crmUrlTemplate = dashboardContext.state.viewState.data?.view.settings.crmUrlTemplate;

  const exampleConversationsMetadataFields = conversations.reduce((acc, curr) => {
    if (curr.optional_metadata) {
      Object.keys(curr.optional_metadata).forEach(f => acc.add(f));
    }
    return acc;
  }, new Set<string>());

  const defaultColumns: TableColumnsType = [
    {
      title: "Conversation Key",
      dataIndex: "conversation_key",
      key: "conversation_key",
      width: "150px",
      render: (value, record) =>
        crmUrlTemplate ? (
          <div style={{ width: "125px" }}>
            <AntTooltip title={"View in CRM"}>
              <Typography.Link
                target="_blank"
                href={renderCrmUrl(crmUrlTemplate, record as ConversationMetadata)}
              >
                <Space>
                  <span>{value}</span>
                  <SelectOutlined rotate={90} />
                </Space>
              </Typography.Link>
            </AntTooltip>
          </div>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: "Dataset",
      dataIndex: "dataset_display_name",
      key: "dataset_name",
      render: d => d ?? conversationMetadata?.dataset_name,
      width: "100px",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: t => moment(t).format("MM-DD-YYYY HH:mm:ss"),
      width: "130px",
    },
    {
      title: "User Story",
      dataIndex: "user_story",
      key: "user_story",
      render: us => <Typography.Text>{us}</Typography.Text>,
    },
  ];

  const metadataColumns: TableColumnsType = Array.from(exampleConversationsMetadataFields).map(
    field => ({
      title: field,
      dataIndex: `optional_metadata`,
      key: `metadata-${field}`,
      render: m => m[field],
    })
  );

  return (
    <div>
      {conversationMetadata && (
        <ConversationModal
          visible={modalVisible}
          setVisible={setModalVisible}
          conversationMetadata={conversationMetadata}
        />
      )}
      <Row style={{ margin: "10px 0" }}>
        <Divider>Example Conversations</Divider>
        <Col span={2}>
          <Typography.Text>Show Metadata Columns:</Typography.Text>
        </Col>
        <Col span={22}>
          {metadataColumns.map(({ key, title }) => (
            <CheckableTag
              key={key}
              checked={metadataColumnsVisible.includes(key as string)}
              onChange={checked => {
                if (checked) {
                  setMetadataColumnsVisible([...metadataColumnsVisible, key as string]);
                } else {
                  setMetadataColumnsVisible(metadataColumnsVisible.filter(col => col !== key));
                }
              }}
              className={`select-metadata-cols ${
                metadataColumnsVisible.includes(key as string) ? "selected" : ""
              }`}
            >
              {title as string}
            </CheckableTag>
          ))}
        </Col>
      </Row>
      <Table
        dataSource={conversations}
        rowKey={(conv: ConversationMetadata) => conv.conversation_id}
        locale={{ emptyText: "No example conversations found" }}
        pagination={false}
        size="middle"
        scroll={{ x: true, y: 500 }}
        className="rounded"
        onRow={row => ({
          onClick: () => {
            setConversationMetadata(row);
            setModalVisible(true);
          },
        })}
        columns={
          [
            ...defaultColumns,
            ...metadataColumns.filter(col => metadataColumnsVisible.includes(col.key as string)),
          ] as ColumnGroupType<ConversationMetadata>[]
        }
      />
    </div>
  );
};
