import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Send, AlertCircle, Ban } from 'lucide-react';
import { MessageComponent } from './components/Message';
import {
  initializeChat,
  sendMessage,
  selectMessages,
  selectHasMessages,
  selectInputPlaceholder,
  selectChatStatus,
  selectError,
  ChatStatus
} from '@/features/agentChat/agentChatSlice';
import { useCustomer } from '@/hooks';
import TypingIndicator from './components/TypingIndicator';
import "./markdown.less";
import { BarChart3, Clock, Users, TrendingUp, MessageCircle } from 'lucide-react';

interface ConversationStartersProps {
  onSelect: (message: string) => void;
}

const ConversationStarters: React.FC<ConversationStartersProps> = ({ onSelect }) => {
  const starters = [
    {
      icon: <BarChart3 className="h-6 w-6" />,
      title: "How are our support metrics trending?",
      description: "Get insights on ticket volume, response times, and resolution rates"
    },
    {
      icon: <Clock className="h-6 w-6" />,
      title: "Are we meeting our SLA targets?",
      description: "Check SLA compliance and identify bottlenecks in response times"
    },
    {
      icon: <Users className="h-6 w-6" />,
      title: "What do customers think about our support?",
      description: "Review CSAT scores and customer feedback trends"
    },
    {
      icon: <TrendingUp className="h-6 w-6" />,
      title: "How is the support team performing?",
      description: "Analyze agent productivity and workload distribution"
    },
    {
      icon: <MessageCircle className="h-6 w-6" />,
      title: "What are our most common support issues?",
      description: "Identify frequently reported problems and their solutions"
    },
    {
      icon: <Ban className="h-6 w-6" />,
      title: "Where are tickets getting stuck?",
      description: "Analyze bottlenecks and delays in the support process"
    }
  ];

  return (
    <div className="flex-1 flex items-center justify-center">
      <div className="max-w-4xl w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {starters.map((starter, index) => (
          <Card 
            key={index}
            className="p-4 hover:bg-gray-50 cursor-pointer transition-colors"
            onClick={() => onSelect(starter.title)}
          >
            <div className="flex items-start gap-4">
              <div className="text-blue-500">
                {starter.icon}
              </div>
              <div>
                <h3 className="font-medium mb-2">{starter.title}</h3>
                <p className="text-sm text-gray-500">{starter.description}</p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

const StatusIndicator: React.FC<{ status: ChatStatus }> = ({ status }) => {
  const error = useSelector(selectError);
  
  switch (status) {
    case ChatStatus.ERROR:
      return (
        <div className="mb-4 p-2 bg-red-100 text-red-700 rounded flex items-center gap-2">
          <AlertCircle className="h-4 w-4" />
          <span>{error}</span>
        </div>
      );
    case ChatStatus.SENDING:
    case ChatStatus.WAITING_FOR_AGENT:
      return <TypingIndicator />;
    default:
      return null;
  }
};

const MessageList: React.FC = () => {
  const messages = useSelector(selectMessages);
  const hasMessages = useSelector(selectHasMessages);
  const dispatch = useDispatch<AppDispatch>();
  const { customer } = useCustomer();
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleStarterSelect = async (message: string) => {
    await dispatch(initializeChat({
      customerId: customer.id,
      initialMessage: message
    }));
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const shouldScroll = 
        scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight < 100;
      
      if (shouldScroll) {
        scrollElement.scrollTo({
          top: scrollElement.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }, [messages]);

  if (!hasMessages) {
    return <ConversationStarters onSelect={handleStarterSelect} />;
  }

  return (
    <ScrollArea className="flex-1 pr-4" ref={scrollRef}>
      <div className="space-y-4 p-4">
        {messages.map((message, index) => (
          <MessageComponent key={index} message={message} />
        ))}
      </div>
    </ScrollArea>
  );
};

const MessageInput: React.FC = () => {
  const [newMessage, setNewMessage] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { customer } = useCustomer();
  const status = useSelector(selectChatStatus);
  const placeholder = useSelector(selectInputPlaceholder);
  const canSend = status === ChatStatus.READY && newMessage.trim().length > 0;

  const handleSend = async () => {
    if (!canSend) return;
    
    const message = newMessage.trim();
    setNewMessage('');

    if (!isInitialized) {
      await dispatch(initializeChat({
        customerId: customer.id,
        initialMessage: message
      }));
      setIsInitialized(true);
    } else {
      await dispatch(sendMessage({ 
        customerId: customer.id,
        message 
      }));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="flex gap-2 pt-4">
      <Input
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
        className="flex-grow"
      />
      <Button 
        onClick={handleSend}
        disabled={!canSend}
        size="icon"
      >
        <Send className="h-4 w-4" />
      </Button>
    </div>
  );
};

export const AgentChat: React.FC = () => {
  const status = useSelector(selectChatStatus);

  return (
    <div className="h-full w-full flex flex-col">
      <div className="flex-1 flex flex-col min-h-0">
        <MessageList />
        <div className="px-4">
          <StatusIndicator status={status} />
          <MessageInput />
        </div>
      </div>
    </div>
  );
};

export default AgentChat;
