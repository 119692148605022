// src/store.ts
import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import metricsViewReducer from "./features/metricsView/metricsViewSlice";
import agentChatReducer from "./features/agentChat/agentChatSlice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    metricsView: metricsViewReducer,
    agentChat: agentChatReducer,
  },
});

// Types for usage in your hooks
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
