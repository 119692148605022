import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, notification, Row, Select, Spin } from "antd";
import { useParams } from "react-router";
import { isAPIError, User } from "../../indexTypes";
import { getCustomers, getUser, updateUser } from "../../reportApi";

const sorter = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());

const useUser = (userId?: string) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!userId) {
      return;
    }
    const f = async () => {
      setLoading(true);
      try {
        setUser(await getUser(userId));
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [userId]);

  return { user, loading };
};

const useCustomers = () => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<string[]>();

  useEffect(() => {
    const f = async () => {
      setLoading(true);
      try {
        setCustomers((await getCustomers()).customers.map(c => c.id).sort(sorter));
      } finally {
        setLoading(false);
      }
    };
    f();
  }, []);

  return { customers, loading };
};

export const UserPage = () => {
  const [form] = Form.useForm();

  const { userId } = useParams();

  const { user, loading: userIsLoading } = useUser(userId);
  const { customers, loading: customersAreLoading } = useCustomers();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const loading = userIsLoading || customersAreLoading;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (!user) {
      return;
    }
    setFormSubmitting(true);
    user.customers = values.customers;
    updateUser(user)
      .then(r => {
        console.log(r.status);
        if (isAPIError(r)) {
          notification.open({
            message: `Failed to save User: ${r.description}`,
            duration: 5,
          });
        } else {
          notification.open({
            message: `User ${r.username} saved!`,
            duration: 5,
          });
        }
        setFormSubmitting(false);
      })
      .catch(e => {
        console.error(e);
        notification.open({
          message: `Failed to save User: ${e}`,
          duration: 5,
        });
        setFormSubmitting(false);
      });
  };

  return (
    <Spin spinning={loading}>
      {user && customers && (
        <Row style={{ margin: "0 24px 24px" }}>
          <Col span={10}>
            <Card title="Update User">
              <Form
                form={form}
                onFinish={onFinish}
                initialValues={{ customers: user.customers.sort(sorter) }}
              >
                <Form.Item label="id">{user.id}</Form.Item>
                <Form.Item label="email">{user.username}</Form.Item>
                <Form.Item label="cognitoSub">{user.cognitoSub}</Form.Item>
                <Form.Item label="customers" name="customers">
                  <Select
                    key={user.customers.join(",")}
                    mode="multiple"
                    defaultValue={user.customers}
                  >
                    {customers.map(c => (
                      <Select.Option key={c}>{c}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit" loading={formSubmitting}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </Spin>
  );
};
