import { Sentiment, TimeseriesMetadata } from "../indexTypes";
import { ParsedExpression } from "./expressionsDslTypes";

export type CustomerId = string;
export type TaxonomyId = string;

export interface View {
  id: string;
  name: string;
  description?: string;
  taxonomyId: TaxonomyId;
  customerId: CustomerId;
  settings: Settings;
  expression: ParsedExpression;
  lastClassifiedConversationTimestamp: string;
}

export interface Settings {
  defaultGranularity: string;
  defaultPeriodLength?: number;
  defaultStartDate?: string;
  defaultSelectedMetadata?: string[];
  crmUrlTemplate?: string;

  filterFieldNames: string[];
  metadataAnalysisOptions: MetadataAnalysisOptions;

  legendText?: string;
}

export interface MetricsView {
  id: string;
  customerId: string;
  name: string;
  description: string;
  settings: MetricsViewSettings;
}

export interface MeterViewSettings {
  type: "meter";
  title: string;
  metric: string;
  minimumValue: number;
  maximumValue: number;
  buckets: { bucketMinimum: number; bucketMaximum: number; bucketColor: string }[];
}

// Union any additional widget settings types here
export type WidgetSettings = MeterViewSettings;

export interface MetricsViewSettings {
  defaultTimeWindow: "week" | "month" | "quarter" | "year";
  widgets: WidgetSettings[];
}

export interface MetadataAnalysisOptions {
  [key: string]: { display_name: string; is_default?: boolean };
}

export interface FilterOptions {
  [key: string]: (string | number)[];
}

export type TaxonomyNodeId = string;

export interface TaxonomyNodeItem {
  id: TaxonomyNodeId;
  resolvedName: string;
  children: TaxonomyNodeItem[];
}

export interface VisibleTaxonomyNodeItem {
  id: TaxonomyNodeId;
  resolvedName: string;
  children: VisibleTaxonomyNodeItem[];
  visibilityReason: NodeVisibilityReason;
  count: number;
}

export interface Taxonomy {
  id: string;
  name?: string;
  description?: string;
  rootNodes: TaxonomyNodeItem[];
}

export interface TaxonomyTimeseriesMetadata {
  id: string;
  name: string;
  mean: number;
  total: number;
  rawCounts: TimeseriesMetadata;
  percentCounts: TimeseriesMetadata;
}

export interface TaxonomyNodeData {
  id: TaxonomyNodeId;
  name: string;
  childIds: string[];
  grandchildCount: number;
  categoryId: number;
  mean: number;
  total: number;
  percent: number;
  rawCounts: TimeseriesMetadata;
  percentCounts: TimeseriesMetadata;
  latestPeriodTrend: number;
  sentiment?: Sentiment;
}

export interface TaxonomyLeafNodeMetadata {
  taxonomyNodeId: TaxonomyNodeId;
  conversationCount: number;
  sentiment: Sentiment;
}

export type MetadataCounts = {
  metadataFieldName: string;
  metadataValueCounts: { [metadataValueName: string]: number };
};

export type MetadataCountsChartDatum = {
  key: string;
  value: number;
  count: number;
  name: string;
};

export enum ConversationMetadataFieldTypeName {
  CATEGORICAL_STRING = "categorical_string",
  CATEGORICAL_NUMERIC = "categorical_numeric",
  BOOLEAN = "boolean",
}

export type BooleanMetadataFieldType = {
  metadata_field_type: ConversationMetadataFieldTypeName.BOOLEAN;
  options: boolean[];
};

export type CategoricalStringMetadataFieldType = {
  metadata_field_type: ConversationMetadataFieldTypeName.CATEGORICAL_STRING;
  options: string[];
};

export type CategoricalNumericMetadataFieldType = {
  metadata_field_type: ConversationMetadataFieldTypeName.CATEGORICAL_NUMERIC;
  options: number[];
};

export type ConversationMetadataFieldTypes =
  | CategoricalStringMetadataFieldType
  | CategoricalNumericMetadataFieldType
  | BooleanMetadataFieldType;

export type ConversationMetadataField = {
  id: string;
  customerId: CustomerId;
  datasetId: number;
  fieldName: string;
  displayName: string;
  fieldType: ConversationMetadataFieldTypes;
};

export type SearchNodesResponse = {
  matchingTaxonomyNodeIds: TaxonomyNodeId[];
};

export enum NodeVisibilityReason {
  NO_SEARCH_VALUE_SET = "NO_SEARCH_VALUE_SET",
  SEARCH_EXACT_MATCH = "SEARCH_EXACT_MATCH",
  SEARCH_SEMANTIC_MATCH = "SEARCH_SEMANTIC_MATCH",
  IS_MATCHED_NODE_DESCENDENT = "IS_MATCHED_NODE_DESCENDENT",
  IS_MATCHED_NODE_ANCESTOR = "IS_MATCHED_NODE_ANCESTOR",
}
