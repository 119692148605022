import { Button, Col, Form, Input, notification, Row, Upload } from "antd";
import React, { useState } from "react";
import { createDemoV2, uploadDemoLogoToS3 } from "../../demoApi";
import { CustomerUIModel } from "../../hooks";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Demos {
  [customer: string]: {
    keys: string[];
    ios?: string;
    android?: string;
    logo?: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UploadableCustomerImage = ({ customer, logo }: { customer: string; logo?: string }) => {
  const baseImg = logo
    ? `https://spiral-public-demo-data.s3.amazonaws.com/${customer}/${logo}`
    : "";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customS3Upload = async (options: any) => {
    const { onSuccess, onError, file } = options;
    uploadDemoLogoToS3(customer, file, "logo.png")
      .then(() => {
        notification.open({
          message: `logo saved!`,
          duration: 5,
        });
        onSuccess("success");
      })
      .catch(error => {
        notification.open({
          message: `Failure saving logo!`,
          duration: 5,
        });
        onError(error);
      });
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={customS3Upload}
    >
      <img src={baseImg} alt="logo" style={{ width: 80, maxWidth: 80, maxHeight: 40 }} />
    </Upload>
  );
};

const DemoMaker = ({ customers }: { customers: CustomerUIModel[] }) => {
  const [companyName, setCompanyName] = useState<string>();
  const [iosUrl, setIOSUrl] = useState<string>();
  const [androidUrl, setAndroidUrl] = useState<string>();
  const [addDemoSuccess, setAddDemoSuccess] = useState<string>("");

  const onAddDemoV2 = async () => {
    try {
      if (!companyName) return;

      const resp = await createDemoV2(companyName, iosUrl, androidUrl);
      if (!resp.error) {
        setAddDemoSuccess(`V2: Successfully added ${companyName}!`);
      } else {
        setAddDemoSuccess(`V2: Error adding ${companyName}! ${resp.message}`);
      }
    } catch {
      setAddDemoSuccess(`V2: Unable to add company ${companyName}. Unknown error.`);
    }
  };

  const companyExists = customers.some(({ id }) => id == companyName);

  return (
    <Row style={{ margin: "0 24px 24px" }}>
      <Col span={5}>
        <Form autoComplete="off">
          <Form.Item
            name="companyName"
            rules={[
              {
                required: true,
                pattern: /^[\w\d-_]+$/g,
                type: "string",
                message:
                  "Contains invalid characters. Use only letters, numbers, dash, or underscore",
              },
            ]}
            hasFeedback={companyExists}
            validateStatus={companyExists ? "warning" : companyName ? "success" : "error"}
            help={
              companyExists
                ? `Customer ${companyName} already exists!`
                : companyName
                ? `New customer: ${companyName}`
                : ""
            }
          >
            <Input
              placeholder="company name"
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="iosUrl"
            rules={[
              {
                type: "url",
                message: "Value must be a URL",
              },
              {
                pattern: /^https:\/\/apps\.apple\.com\/.*\/id\d+.*/,
                message: "Doesn't match expected format for App Store URL",
              },
            ]}
          >
            <Input placeholder="ios app url" onChange={e => setIOSUrl(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="androidUrl"
            rules={[
              {
                type: "url",
                message: "Value must be a URL",
              },
              {
                pattern: /^https:\/\/play\.google\.com\/.*\?.*id=.*/,
                message: "Doesn't match expected format for a Play Store URL",
              },
            ]}
          >
            <Input placeholder="android app url" onChange={e => setAndroidUrl(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={onAddDemoV2}
              disabled={!companyName || companyName.trim() === ""}
              type="default"
            >
              Add Demo V2
            </Button>
          </Form.Item>

          <div>{addDemoSuccess}</div>
        </Form>
      </Col>
    </Row>
  );
};

export const DemoManagerPage = ({ customers }: { customers: CustomerUIModel[] }) => {
  return (
    <div className="selectable">
      <Row key="spacer" style={{ margin: "0 24px 24px" }}>
        <Col />
      </Row>
      <Row key="demo-header" style={{ margin: "0 24px 24px" }}>
        <Col span={24}>
          <h2>Demo Manager</h2>
        </Col>
      </Row>
      <DemoMaker customers={customers} />
    </div>
  );
};
