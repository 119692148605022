import { BarChartOutlined, PercentageOutlined, PieChartOutlined } from "@ant-design/icons";
import { Tooltip as AntTooltip, Button, Col, Row, Select, Space, Spin, Statistic } from "antd";
import React, { memo, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCustomer, useDataLoader } from "../hooks";
import { isAPIError, TaxonomyNodeFilters } from "../indexTypes";
import { getMetadataCounts } from "../reportApi";
import { ParsedExpression } from "../types/expressionsDslTypes";
import { displayName, truncate } from "../utils";

export const ratingNames = [
  "score",
  "stars",
  "Star Rating",
  "review_rating",
  "Overall Rating",
  "star_rating",
  "rating_value",
  "trip_rating",
];

const colors = ["#465FC3", "RGBA(70,95,195,0.39)", "#141446", "#7A77FF"];
const pieColors = ["#223065", "#4558A5", "#7080C2", "#B3BDE7", "#DBE0F5"];

export type ChartType = "pie" | "bar";

export const MetadataAnalysisWidget = memo(
  ({
    chartTitle,
    type,
    viewId,
    startDate,
    endDate,
    metadataAnalysisChoice,
    taxonomyNodeId,
    yaxis = true,
    filters,
    taxonomyNodeFilters,
  }: {
    chartTitle: string;
    type: ChartType;
    viewId: string;
    startDate: string;
    endDate: string;
    metadataAnalysisChoice: string;
    taxonomyNodeId?: string;
    yaxis?: boolean;
    filters?: ParsedExpression;
    taxonomyNodeFilters?: TaxonomyNodeFilters;
  }) => {
    const [chartType, setChartType] = useState<ChartType>(type);
    const [normalizationChoice, setNormalizationChoice] = useState<string>("max");
    const [normalized, setNormalized] = useState<boolean>(false);
    const customer = useCustomer();

    const { loading, data: metadataCountsResp } = useDataLoader(
      async controller => {
        const resp = await getMetadataCounts(
          customer.customer.id,
          viewId,
          startDate,
          endDate,
          metadataAnalysisChoice,
          taxonomyNodeId,
          10,
          filters,
          taxonomyNodeFilters,
          controller.signal
        );
        if (isAPIError(resp)) {
          console.error("Error fetching metadata counts", resp);
          throw new Error(resp.error);
        }

        return resp;
      },
      [
        customer.customer.id,
        viewId,
        startDate,
        endDate,
        metadataAnalysisChoice,
        taxonomyNodeId,
        filters,
        taxonomyNodeFilters,
      ]
    );

    const normalizationOptions = useMemo(() => {
      if (!metadataCountsResp) {
        return [];
      }
      return [
        { name: "Maximum Value", value: "max" },
        { name: "Minimum Value", value: "min" },
        ...Object.keys(metadataCountsResp.metadataValueCounts)
          .map(metadataValueName => ({
            name: displayName(metadataValueName),
            value: metadataValueName,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ];
    }, [metadataCountsResp]);

    const metadataChartData = useMemo(() => {
      if (!metadataCountsResp) {
        return [];
      }
      const valuesAndCounts = metadataCountsResp.metadataValueCounts;
      let selectedNormalizationValue: number;
      // choice defaults to "max"
      if (normalizationChoice === "max") {
        selectedNormalizationValue = Math.max(...Object.values(valuesAndCounts));
      } else if (normalizationChoice === "min") {
        selectedNormalizationValue = Math.min(...Object.values(valuesAndCounts));
      } else {
        selectedNormalizationValue = valuesAndCounts[normalizationChoice];
      }
      const total = Object.values(valuesAndCounts).reduce(
        (acc: number, metadataValueCount: number) => acc + metadataValueCount,
        0
      );

      return Object.entries(valuesAndCounts)
        .map(([metadataValueName, counts]) => ({
          key: metadataValueName,
          value: normalized ? counts / selectedNormalizationValue : (counts / total) * 100,
          count: counts,
          name: displayName(metadataValueName),
        }))
        .sort((a, b) => {
          if (ratingNames.includes(metadataAnalysisChoice)) {
            const aInt = parseInt(a.key);
            const bInt = parseInt(b.key);
            return aInt - bInt;
          }
          return b.value - a.value;
        });
    }, [metadataCountsResp, metadataAnalysisChoice, normalizationChoice, normalized]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatter = (n: number, _name: string, item: any) => {
      const msg = `${n.toFixed(2)}${normalized ? "x" : "%"}`;
      return `${msg} (${item.payload.count})`;
    };

    return (
      <Spin spinning={loading}>
        <div style={{ paddingLeft: "25px", paddingRight: "15px", width: "100%" }}>
          <Row justify="space-between">
            <Col span={24} style={{ textAlign: "center" }}>
              <Statistic value={chartTitle} style={{ marginBottom: "0.1em", fontSize: "20px" }} />
            </Col>
          </Row>
          <Row>
            {metadataCountsResp && normalized && (
              <Col span={12} style={{ marginBottom: "10px" }}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Normalization options"
                  size="small"
                  defaultValue={"max"}
                  value={normalizationChoice}
                  onChange={value => {
                    setNormalizationChoice(value);
                  }}
                >
                  {normalizationOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            )}
            <Col span={normalized ? 12 : 24} style={{ textAlign: "right", marginBottom: "0em" }}>
              <Space>
                <span>
                  <Button
                    icon={<BarChartOutlined />}
                    type="link"
                    onClick={() => setChartType("bar")}
                  />
                  <Button
                    icon={<PieChartOutlined />}
                    type="link"
                    onClick={() => {
                      setChartType("pie");
                      setNormalized(false);
                    }}
                  />
                </span>
                <span style={{ color: "#666" }}>|</span>
                <AntTooltip
                  placement="left"
                  title={() => (
                    <span>
                      <strong>Normalize</strong>
                      <br />
                      Show proportion to the selected value
                    </span>
                  )}
                >
                  <Button
                    icon={<PercentageOutlined />}
                    type={normalized ? "primary" : "link"}
                    onClick={() => {
                      setChartType("bar");
                      setNormalized(!normalized);
                    }}
                    shape="circle"
                  />
                </AntTooltip>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{ width: "100%", height: "300px" }}>
                {metadataCountsResp && metadataChartData.length !== 0 && (
                  <ResponsiveContainer>
                    {chartType === "bar" ? (
                      <BarChart data={metadataChartData}>
                        <XAxis dataKey="name" axisLine={false} tickLine={false} />
                        {yaxis && (
                          <YAxis
                            type="number"
                            unit={normalized ? "x" : "%"}
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={value => `${value.toFixed(2)}`}
                            domain={[
                              0,
                              normalized
                                ? Math.max(1, ...metadataChartData.map(e => e.value))
                                : "auto",
                            ]}
                          />
                        )}
                        <CartesianGrid
                          vertical={false}
                          stroke="rgba(165,170,191,0.5)"
                          strokeDasharray="2 7"
                        />
                        <Tooltip isAnimationActive={false} formatter={formatter} />
                        <Bar dataKey="value" fill={colors[0]} radius={10}>
                          {metadataChartData.map((entry) => (
                            <Cell
                              key={entry.key}
                              fill={
                                normalized && entry.key === normalizationChoice
                                  ? pieColors[3]
                                  : colors[0]
                              }
                            />
                          ))}
                        </Bar>
                        {normalized && (
                          <ReferenceLine
                            y={1}
                            stroke="rgba(165,170,191,0.9)"
                            strokeDasharray="5 4"
                            strokeWidth={1.5}
                          />
                        )}
                      </BarChart>
                    ) : (
                      <PieChart>
                        <Tooltip isAnimationActive={false} formatter={formatter} />
                        <Pie
                          key={Math.random()}
                          data={metadataChartData}
                          dataKey="value"
                          animationDuration={250}
                          animationBegin={0}
                          labelLine={false}
                          label={({ name, value }) =>
                            value >= 4 ? truncate(name, 14, false) : undefined
                          }
                        >
                          {metadataChartData.map((entry, index) => (
                            <Cell key={entry.key} fill={pieColors[index % pieColors.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    )}
                  </ResponsiveContainer>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
);

MetadataAnalysisWidget.displayName = "MetadataCountsWidget";
