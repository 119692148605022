import { headers, apiV2Endpoint, fetchWithReject } from "@/utils";

export type TaxonomyNodeId = string;

export interface MarkdownBlock {
  type: 'markdown';
  markdown: string;
}

export interface TaxonomyNodeLinkBlock {
  type: 'taxonomy_node_link';
  node_id: TaxonomyNodeId;
  display_text: string;
}

export interface PieChartBlock {
  type: 'pie_chart';
  title: string;
  data: Record<string, number>;
}

export interface BarChartBlock {
  type: 'bar_chart';
  title: string;
  data: Record<string, number>;
}

export interface DataPoint {
  x: string;
  y: number;
}

export interface ComparisonDataPoint {
  x: string;
  y1: number;
  y2: number;
}

export interface LineChartBlock {
  type: 'line_chart';
  title: string;
  data: DataPoint[];  // Series of data points for the line chart
}

export interface LineChartComparisonBlock {
  type: 'line_chart_comparison';
  title: string;
  data: ComparisonDataPoint[];  // Series of data points for comparing two lines
}

export interface ConversationMetadataBlock {
  type: 'example_conversations';
  customer_id: string;
  conversation_ids: number[];
}

export type AgentResponseBlock = 
  | MarkdownBlock 
  | TaxonomyNodeLinkBlock 
  | PieChartBlock 
  | BarChartBlock 
  | LineChartBlock
  | LineChartComparisonBlock
  | ConversationMetadataBlock;

export interface AgentMessage {
  role: 'agent';
  content: AgentResponseBlock[];
}

export interface UserMessage {
  role: 'user';
  content: string;
}

export interface ToolUseEvent {
  tool_name: string;
  reasoning: string;
}

export interface ToolUseEventMessage {
  role: 'tool_use_event';
  content: ToolUseEvent;
}

export type Message = AgentMessage | UserMessage | ToolUseEventMessage;

export interface AgentState {
  id: string;
  messages: Message[];
}

export const getAgentChat = async (
  customerId: string,
  agentChatId: string,
  signal?: AbortSignal
): Promise<AgentState | null> => {
  return fetchWithReject(
    `${apiV2Endpoint}/customers/${customerId}/agent-chat/${agentChatId}`,
    {
      method: "GET",
      headers: await headers(),
      mode: "cors",
      signal
    }
  ).then(r => r.json());
};

export const createAgentChat = async (
  customerId: string,
  message: string,
  signal?: AbortSignal
): Promise<AgentState> => {
  return fetchWithReject(
    `${apiV2Endpoint}/customers/${customerId}/agent-chat`,
    {
      method: "POST", 
      headers: await headers(),
      mode: "cors",
      body: JSON.stringify({ message }),
      signal
    }
  ).then(r => r.json());
};

export const sendAgentChatMessage = async (
  customerId: string,
  agentChatId: string,
  message: string,
  signal?: AbortSignal
): Promise<void> => {
  return fetchWithReject(
    `${apiV2Endpoint}/customers/${customerId}/agent-chat/${agentChatId}`,
    {
      method: "POST",
      headers: await headers(),
      mode: "cors", 
      body: JSON.stringify({ message }),
      signal
    }
  ).then(r => r.json());
};
