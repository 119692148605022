import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * A utility function used by shadcn/ui components to merge Tailwind CSS classes
 * without style conflicts.
 * 
 * @param inputs - Array of class names, objects, or conditional expressions
 * @returns Merged and deduplicated class names string
 * 
 * This function:
 * 1. Uses clsx to conditionally join class names together
 * 2. Uses twMerge to properly merge Tailwind classes without conflicts
 * 
 * Example usage in shadcn components:
 * cn('px-2 py-1', className) // Merges default and user-provided classes
 * cn('bg-blue-500', isActive && 'bg-blue-700') // Conditional classes
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

