import { Button, Divider, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useCustomer } from "../hooks";

import { getExamples, getTimeseries } from "../api";

import { TimeseriesChart } from "../components/ReportsComponents";

import {
  Alert,
  Tooltip as AntTooltip,
  Card,
  Col,
  Collapse,
  DatePicker,
  Empty,
  Row,
  Space,
  Spin,
  Statistic,
} from "antd";
import moment from "moment";
import { MetadataField } from "../reports";

import { ExportOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import "../components/ReportsViewer.less";

const { Title } = Typography;

type TimeseriesGroup = MetadataField & {
  total: number;
  mean: number;
  scale: number;
};

type TimeseriesGroupWithChildren = TimeseriesGroup & {
  groups: { [key: string]: TimeseriesGroup | TimeseriesGroupWithChildren };
};

const formatPercent = (p: number, precision = 2) =>
  `${p < 0.0001 ? "< 0.01" : (p * 100).toFixed(precision)}%`;

const ExamplesTable = ({
  tagName,
  group,
  startDate,
  endDate,
  customerId,
}: {
  tagName: string;
  group: TimeseriesGroup;
  startDate: string;
  endDate: string;
  customerId: string;
}) => {
  const { customer } = useCustomer();
  const [examples, setExamples] = useState<object[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const tags = { [tagName]: group.fieldName === "Other" ? null : group.fieldName };
        setExamples(
          await getExamples(
            customerId,
            startDate,
            endDate,
            tags,
            customer.index.evidenceTableMetadataFields
          )
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    customer.index.evidenceTableMetadataFields,
    customerId,
    endDate,
    group.fieldName,
    startDate,
    tagName,
  ]);

  if (!examples) {
    return (
      <Spin spinning={loading}>
        <Empty description="" />
      </Spin>
    );
  }
  if (examples.length === 0) {
    return <Empty description="" />;
  }
  const columns = Object.keys(examples[0]).map(k => {
    let base: ColumnType<object> = { key: k, title: k, dataIndex: k };
    if (k === "Timestamp") {
      base = {
        ...base,
        render: (text: string) => moment(text).format("lll"),
      };
    } else if (k === "Case Link") {
      base = {
        ...base,
        render: (text: string) => (
          <Button type="link" href={text} target="_blank" icon={<ExportOutlined />}></Button>
        ),
      };
    }
    return base;
  });

  return <Table dataSource={examples} columns={columns} />;
};

const renderGroupCollapsePanelHeader = (
  key: string,
  group: TimeseriesGroup,
  total: number,
  granularity: string,
  level: number,
  childCount?: number
) => {
  return (
    <Space size={30}>
      <span>{key}</span>
      <div className="dot" />
      <div className="field">
        <AntTooltip title={() => <span>Percent of total contacts over the whole period</span>}>
          <div className="field-desc">
            Percent <InfoCircleOutlined />
          </div>
          {formatPercent(group.total / total)}
        </AntTooltip>
      </div>
      <div className="dot" />
      <div className="field">
        <AntTooltip
          title={() => <span>Total number of contacts in this group over the whole period</span>}
        >
          <div className="field-desc">
            Volume <InfoCircleOutlined />
          </div>
          <span>{group.total.toLocaleString()}</span>
        </AntTooltip>
      </div>
      <div className="dot" />
      <div className="field">
        <AntTooltip
          title={() => (
            <span>
              Average number of contacts in this group per {granularity} over the whole period
            </span>
          )}
        >
          <div className="field-desc">
            Avg Rate <InfoCircleOutlined />
          </div>
          <span>
            {Math.round(group.mean).toLocaleString()}
            <small>/{granularity}</small>
          </span>
        </AntTooltip>
      </div>
      <div className="dot" />
      <TimeseriesChart
        metadata={group}
        graphheight={55}
        graphwidth={132}
        range={level > 0 ? [0, group.scale] : undefined}
      />
      <div className="highlight" />
      <Space size={19} className="tags">
        {childCount && (
          <Button key={`{childCount} Sub Issues`} type="primary">
            {childCount} Sub Issue{childCount > 1 ? "s" : ""}
          </Button>
        )}
      </Space>
    </Space>
  );
};

const renderGroupRow = (
  group: TimeseriesGroup,
  startDate: string,
  endDate: string,
  customerId: string,
  tags: string[],
  level = 0
) => {
  return (
    <>
      <Row className="metadata" style={{ padding: "24px" }}>
        <Col span={24}>
          <TimeseriesChart
            metadata={group}
            graphheight={320}
            xaxis
            yaxis
            grid
            animationDuration={150}
            preserve={"preserveEnd"}
            xTickFormat={"MMM D"}
            showTooltip
          />
        </Col>
      </Row>
      <Row className="metadata" style={{ padding: "24px" }}>
        <Col span={24}>
          <ExamplesTable
            tagName={tags[level]}
            group={group}
            startDate={startDate}
            endDate={endDate}
            customerId={customerId}
          />
        </Col>
      </Row>
    </>
  );
};

const renderGroups = (
  groups: TimeseriesGroupWithChildren,
  total: number,
  mean: number,
  granularity: string,
  startDate: string,
  endDate: string,
  customerId: string,
  tags: string[],
  level = 0
) => {
  const otherGroup = groups.groups.Other;
  return (
    <>
{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {otherGroup && level == 0 && (
        <>
          <Collapse.Panel
            key="Other"
            header={renderGroupCollapsePanelHeader("Other", otherGroup, total, granularity, level)}
          >
            {renderGroupRow(otherGroup, startDate, endDate, customerId, tags, level)}
          </Collapse.Panel>
          <Divider />
        </>
      )}
      {Object.entries(groups.groups).map(([key, group]) => {
        if (key === "Other" && level === 0) {
          return "";
        }
        return (
          <Collapse.Panel
            key={key}
            header={renderGroupCollapsePanelHeader(
              key,
              group,
              total,
              granularity,
              level,
              "groups" in group ? Object.keys(group.groups).length : undefined
            )}
            className={"groups" in group ? "superissue" : "subissue"}
          >
            {"groups" in group ? (
              <Collapse bordered={false} destroyInactivePanel>
                <Row className="metadata" style={{ padding: "24px", marginLeft: "0" }}>
                  <Col>
                    <TimeseriesChart
                      metadata={group}
                      graphheight={320}
                      xaxis
                      yaxis
                      grid
                      animationDuration={150}
                      preserve={"preserveEnd"}
                      xTickFormat={"MMM D"}
                      showTooltip
                    />
                  </Col>
                </Row>
                {renderGroups(
                  group,
                  total,
                  mean,
                  granularity,
                  startDate,
                  endDate,
                  customerId,
                  tags,
                  level + 1
                )}
              </Collapse>
            ) : (
              renderGroupRow(group, startDate, endDate, customerId, tags, level)
            )}
          </Collapse.Panel>
        );
      })}
    </>
  );
};

const ExplorePage: React.FC = () => {
  const { customer } = useCustomer();
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const tags = customer && customer.index.defaultTagGroupBy;

  const [timeseriesLoading, setTimeseriesLoading] = useState(false);
  const [timeseriesData, setTimeseriesData] = useState<MetadataField>();

  const [startDate, setStartDate] = useState(moment("2023-06-01"));
  const [endDate, setEndDate] = useState(moment("2023-08-31"));

  const [timeseriesGroupsLoading, setTimeseriesGroupsLoading] = useState(false);
  const [timeseriesGroups, setTimeseriesGroups] = useState<TimeseriesGroupWithChildren>();

  const granularity = "day";

  useEffect(() => {
    const fetchData = async () => {
      setTimeseriesLoading(true);
      setTimeseriesGroupsLoading(true);
      try {
        getTimeseries(
          customer.id,
          startDate.toISOString(),
          endDate.toISOString(),
          granularity
        ).then(data => {
          setTimeseriesData(data);
          setTimeseriesLoading(false);
        });

        getTimeseries(
          customer.id,
          startDate.toISOString(),
          endDate.toISOString(),
          granularity,
          tags.join(",")
        ).then(data => {
          setTimeseriesGroups(data);
          setTimeseriesGroupsLoading(false);
        });
      } catch (error) {
        // Handle the error
      }
    };

    fetchData();
  }, [customer.id, startDate, endDate, customer.index.defaultTagGroupBy, tags]);

  const total = timeseriesData?.data.map(d => d.count).reduce((a, b) => a + b) ?? 1;
  const mean = total / (timeseriesData?.data.length ?? 1);

  return (
    <div style={{ margin: "24px" }} id="reportsviewer">
      <Alert
        message="Categorization Preview – not all features are implemented!"
        type="info"
        className="rounded"
        closable
      />
      <Row style={{ margin: "24px 0 0" }}>
        <Col span={24}>
          <Title level={3}>Support Conversation Analysis</Title>
        </Col>
      </Row>
      <Row style={{ margin: "24px 0" }}>
        <Col span={24}>
          <Space size="small">
            <label htmlFor="datePicker">Report Range:</label>
            <DatePicker.RangePicker
              id="datePicker"
              onChange={dates => {
                if (dates && dates[0] && dates[1]) {
                  setStartDate(dates[0]);
                  setEndDate(dates[1]);
                }
              }}
              defaultValue={[moment(startDate), moment(endDate)]}
              className="rounded"
            />
          </Space>
        </Col>
      </Row>
      <Row key="reportOverviewCard">
        <Col span={24}>
          <Card className="reportOverviewCard rounded" style={{ marginBottom: 24 }}>
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Spin spinning={timeseriesLoading}>
                  <AntTooltip title={() => "Total Contacts in this Report"}>
                    <Statistic value={total} />
                  </AntTooltip>
                  <TimeseriesChart
                    metadata={timeseriesData}
                    graphheight={300}
                    xaxis
                    yaxis
                    grid
                    animationDuration={150}
                    preserve={"preserveEnd"}
                    percent={false}
                    xTickFormat={"MMM D"}
                    legendText="Frontier Live Support Chats"
                    showTooltip
                  />
                </Spin>
              </Col>
              {/* <Col span={8} style={{ textAlign: "center" }}>
                ¯\_(ツ)_/¯
              </Col> */}
            </Row>
          </Card>
        </Col>
      </Row>
      <Spin spinning={timeseriesGroupsLoading}>
        <Row>
          <Col span={24}>
            <Collapse bordered={false} destroyInactivePanel>
              {timeseriesGroups &&
                renderGroups(
                  timeseriesGroups,
                  total,
                  mean,
                  granularity,
                  startDate.toISOString(),
                  endDate.toISOString(),
                  customer.id,
                  tags
                )}
            </Collapse>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ExplorePage;
