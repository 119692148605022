import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { getAgentChat, createAgentChat, sendAgentChatMessage, AgentState, Message, UserMessage } from '@/agentChatApi';

export enum ChatStatus {
  ERROR = 'error',
  READY = 'ready',
  SENDING = 'sending',
  WAITING_FOR_AGENT = 'waiting',
}

interface ChatState {
  id: string;
  latestAgentState: AgentState | null;
  pendingUserMessage: string | null;
  error: string | null;
}

const initialState: ChatState = {
  id: '',
  latestAgentState: null,
  pendingUserMessage: null,
  error: null,
};

const POLL_INTERVAL = 1000;

async function waitAndPoll(customerId: string, chatId: string): Promise<AgentState> {
  await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL));
  const response = await getAgentChat(customerId, chatId);
  if (!response || !Array.isArray(response.messages)) {
    throw new Error('Invalid response from server');
  }
  return response;
}

async function pollUntilMessageAcknowledged(customerId: string, chatId: string, userMessage: string): Promise<AgentState> {
  let response = await waitAndPoll(customerId, chatId);
  const messages = response.messages;
  const lastUserMessage = [...messages].reverse().find(m => m.role === 'user');
  
  while (!lastUserMessage || !(lastUserMessage.role === 'user' && lastUserMessage.content === userMessage)) {
    response = await waitAndPoll(customerId, chatId);
  }
  
  return response;
}

const pollForAgentResponse = createAsyncThunk(
  'agentChat/pollForAgentResponse',
  async ({ customerId, chatId }: { customerId: string, chatId: string }, { dispatch }) => {
    let response = await waitAndPoll(customerId, chatId);
    let length;
    const getLastMessage = () => response.messages[response.messages.length - 1];

    
    while (getLastMessage().role !== 'agent') {
      length = response.messages.length;
      response = await waitAndPoll(customerId, chatId);

      if (response.messages.length > length) {
        dispatch(agentChatSlice.actions.updateAgentState(response));
      }
    }

    dispatch(agentChatSlice.actions.updateAgentState(response));
    return response;
})

interface InitParams {
  customerId: string;
  initialMessage: string;
}

interface SendMessageParams {
  customerId: string;
  message: string;
}

export const initializeChat = createAsyncThunk(
  'agentChat/initialize',
  async ({ customerId, initialMessage }: InitParams, { dispatch }) => {
    const response = await createAgentChat(customerId, initialMessage);
    
    dispatch(agentChatSlice.actions.setId(response.id));
    // We should be in WAITING state since we just sent the initial message
    dispatch(agentChatSlice.actions.updateInitialState(response));
    
    // Wait for agent's first response
    await dispatch(pollForAgentResponse({customerId, chatId: response.id}));
  }
);

export const sendMessage = createAsyncThunk(
  'agentChat/sendMessage',
  async ({ customerId, message }: SendMessageParams, { dispatch, getState }) => {
    const state = getState() as RootState;
    dispatch(agentChatSlice.actions.setPendingMessage(message));
    
    try {
      await sendAgentChatMessage(customerId, state.agentChat.id, message);
      
      // Wait until message appears in server state
      const ackState = await pollUntilMessageAcknowledged(customerId, state.agentChat.id, message);
      dispatch(agentChatSlice.actions.updateAgentState(ackState));
      dispatch(agentChatSlice.actions.setPendingMessage(null));
      
      // Wait for agent's response
      await dispatch(pollForAgentResponse({customerId, chatId: state.agentChat.id}));
    } catch (error) {
      dispatch(agentChatSlice.actions.setPendingMessage(null));
      dispatch(agentChatSlice.actions.setError((error as Error).message));
      throw error;
    }
  }
);

const agentChatSlice = createSlice({
  name: 'agentChat',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    updateInitialState: (state, action: PayloadAction<AgentState>) => {
      state.latestAgentState = action.payload;
      state.error = null;
    },
    updateAgentState: (state, action: PayloadAction<AgentState>) => {
      state.latestAgentState = action.payload;
      state.error = null;
    },
    setPendingMessage: (state, action: PayloadAction<string | null>) => {
      state.pendingUserMessage = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeChat.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to initialize chat';
      });
  },
});

// Base selector
const selectAgentChatState = (state: RootState): ChatState => state.agentChat;


// Primary selectors
export const selectMessages = createSelector(
  [selectAgentChatState],
  (chat): Message[] => {
    const messages = chat.latestAgentState?.messages || [];
    if (chat.pendingUserMessage !== null) {
      const pendingMessage: UserMessage = {
        content: chat.pendingUserMessage,
        role: 'user',
      };
      return [...messages, pendingMessage];
    }
    return messages;
  }
);

export const selectError = createSelector(
  [selectAgentChatState],
  (chat): string | null => chat.error
);

// Derived selectors
export const selectHasMessages = createSelector(
  [selectMessages],
  (messages): boolean => messages.length > 0
);

export const selectLastMessage = createSelector(
  [selectMessages],
  (messages): Message | null => messages[messages.length - 1] || null
);

export const selectChatStatus = createSelector(
  [selectAgentChatState, selectLastMessage],
  (chat, lastMessage): ChatStatus => {
    if (chat.error) {
      return ChatStatus.ERROR;
    }
    if (chat.pendingUserMessage !== null) {
      return ChatStatus.SENDING;
    }
    if (lastMessage === null || lastMessage.role === 'agent') {
      return ChatStatus.READY;
    }
    return ChatStatus.WAITING_FOR_AGENT;
  }
);



export const selectInputPlaceholder = createSelector(
  [selectChatStatus],
  (status): string => {
    switch (status) {
      case ChatStatus.ERROR:
        return 'An error occurred';
      case ChatStatus.SENDING:
      case ChatStatus.WAITING_FOR_AGENT:
        return 'Waiting for response...';
      case ChatStatus.READY:
        return 'Type your message...';
    }
  }
);

export const { setId, updateAgentState, updateInitialState, setPendingMessage, setError } = agentChatSlice.actions;
export default agentChatSlice.reducer;
