import Icon, {
  CompassOutlined,
  LayoutOutlined,
  LockOutlined,
  StockOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { Button, Menu, MenuProps } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";
import { Link, NavLink, useMatch } from "react-router-dom";
import { CustomerUIModel } from "../hooks";
import { ReportAPIResp, SidebarItem, SidebarSubMenu } from "../indexTypes";
import { isDefined, isSidebarReportItem, isSidebarSubMenu, isSidebarViewItem } from "../utils";

// Helper for auto-expanding the submenus
function getSubmenuExpandPath(reportHierarchy: (SidebarSubMenu | SidebarItem)[], reportId: string) {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!reportHierarchy) {
    return ["subReportSets"];
  }

  // Build the expand paths for each of the submenus in our report hierarchy. While we're doing that,
  // look for the submenu that contains the report we're currently viewing and save its key.
  let matchingMenuKey = "";
  const expandPaths: { [subMenuKey: string]: string[] } = {};
  const buildExpandPaths = (hierarchy: (SidebarSubMenu | SidebarItem)[], parentKeys: string[]) => {
    hierarchy.forEach(item => {
      if (isSidebarSubMenu(item)) {
        const keys = [...parentKeys, item.key];
        expandPaths[item.key] = keys;
        buildExpandPaths(item.items, keys);
        if (
          item.items.some(
            i =>
              (isSidebarReportItem(i) && i.reportSet === reportId) ||
              (isSidebarViewItem(i) && i.viewId === reportId) ||
              (item.key === "Reporting" &&
                isSidebarReportItem(i) &&
                `reporting-${i.reportSet}` === reportId)
          )
        ) {
          matchingMenuKey = item.key;
        }
      }
    });
  };
  buildExpandPaths(reportHierarchy, []);
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return ["subReportSets", ...(expandPaths[matchingMenuKey] ?? [])];
}

const getIcon = (iconString?: string) => {
  if (!iconString) {
    return;
  }
  const IconSVG = () => (
    <img
      style={{ height: "1em", width: "1em", marginTop: "-2px" }}
      alt=""
      src={`data:image/svg+xml;base64,${iconString}`}
    />
  );
  return <Icon component={IconSVG} />;
};

const convertToMenu = (
  item: SidebarItem | SidebarSubMenu,
  isAdmin: boolean,
  isReportingView = false
): ItemType | undefined => {
  if (item.adminOnly && !isAdmin) {
    return;
  }
  if (isSidebarSubMenu(item)) {
    return {
      key: item.key,
      label: item.displayName,
      icon: getIcon(item.iconString) ?? <LayoutOutlined />,
      children: item.items
        .map(i => convertToMenu(i, isAdmin, item.key === "Reporting"))
        .filter(isDefined),
    };
  } else {
    const labelContents = (
      <>
        {item.displayName}{" "}
        {item.adminOnly && (
          <span>
            &nbsp;
            <LockOutlined />
          </span>
        )}
      </>
    );
    let itemKey = "";
    let itemPath = "";
    if (isSidebarViewItem(item)) {
      itemKey = item.viewId;
      itemPath = `/views/${item.viewId}`;
    } else if (isSidebarReportItem(item)) {
      itemKey = isReportingView ? `reporting-${item.reportSet}` : item.reportSet;
      itemPath = isReportingView
        ? `/dashboards/reporting/${item.reportSet}`
        : `/dashboards/${itemKey}`;
    }
    return {
      key: itemKey,
      danger: item.adminOnly,
      icon: getIcon(item.iconString),
      disabled: item.disabled,
      style: item.disabled ? { cursor: "default" } : {},
      label: item.disabled ? (
        labelContents
      ) : (
        <NavLink to={{ pathname: itemPath }}>{labelContents}</NavLink>
      ),
    };
  }
};

export const SpiralMenu = ({
  customer,
  unsetCustomer,
  admin,
  reports,
}: {
  customer: CustomerUIModel;
  unsetCustomer: () => void;
  admin: boolean;
  reports?: ReportAPIResp;
}) => {
  const reportHierarchy = customer.index.reportSetHierarchy;
  function getReportId() {
    // Check if the report_id is in the URL, but ignore the value if it's not a report owned by that customer
// eslint-disable-next-line react-hooks/rules-of-hooks
    const match = useMatch("/dashboards/:reportId");
// eslint-disable-next-line react-hooks/rules-of-hooks
    const reportingMatch = useMatch("/dashboards/reporting/:reportId");
// eslint-disable-next-line react-hooks/rules-of-hooks
    const viewIdMatch = useMatch("/views/:viewId");
    if (match) {
      const { reportId = "" } = match.params as { reportId: string };
      if (reportId && reports && reportId in reports) {
        return reportId;
      }
    } else if (reportingMatch) {
      const { reportId = "" } = reportingMatch.params as { reportId: string };
      if (reportId && reports && reportId in reports) {
        return `reporting-${reportId}`;
      }
    } else if (viewIdMatch) {
      const { viewId = "" } = viewIdMatch.params as { viewId: string };
      const allViewIds: string[] = [];
      const traverseHierarchy = (hierarchy: (SidebarSubMenu | SidebarItem)[]) => {
        hierarchy.forEach(item => {
          if (isSidebarSubMenu(item)) {
            traverseHierarchy(item.items);
          } else if (isSidebarViewItem(item)) {
            allViewIds.push(item.viewId);
          }
        });
      };
      traverseHierarchy(reportHierarchy);
      if (viewId && allViewIds.includes(viewId)) {
        return viewId;
      }
    }
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return customer.index.defaultReportSet ?? undefined;
  }
  const chosenReport = getReportId();

  const newPagesMenuItems: MenuProps["items"] = [
    {
      key: "explore-page",
      icon: <CompassOutlined />,
      label: <NavLink to="/dashboards/explore">Explore</NavLink>,
    },
    {
      key: "ews-page",
      icon: <StockOutlined />,
      label: <NavLink to="/dashboards/early-warning-system">Early Warning System</NavLink>,
    },
  ];

  const adminMenuItems: MenuProps["items"] = [
    {
      key: "admin",
      label: "Admin Pages",
      children: [
        ...newPagesMenuItems,
        { key: "admin-demo", label: <NavLink to="/admin/demos">Demo Manager</NavLink> },
        {
          key: "admin-customer",
          label: (
            <NavLink to={`/admin/customers/${customer.id}/customer`}>Customer Manager</NavLink>
          ),
        },
        { key: "admin-users", label: <NavLink to="/admin/users">Users</NavLink> },
        { key: "admin-datasets", label: <NavLink to="/admin/datasets">Datasets</NavLink> },
        {
          key: "admin-unset",
          label: (
            <Link to="/">
              <Button onClick={() => unsetCustomer()}>UnsetCustomer</Button>
            </Link>
          ),
        },
      ],
    },
  ];

  const menuItems: ItemType[] = [];
  if (customer.id === 'spiral_demo_5') {
    menuItems.push({
      key: 'agent-chat',
      label: (
        <NavLink to="/agent-chat">Spiral Agent</NavLink>
      ),
      icon: <RocketOutlined />,
    });
  }
  if (reports) {
    menuItems.push(...reportHierarchy.map(e => convertToMenu(e, admin)).filter(isDefined));
  }


  if (admin) {
    menuItems.push(...adminMenuItems);
  }

  return reports ? (
    <Menu
      id="sidebarOptions"
      key="sidebarOptions"
      mode="inline"
      defaultOpenKeys={getSubmenuExpandPath(reportHierarchy, chosenReport)}
      defaultSelectedKeys={[chosenReport]}
      items={menuItems}
    />
  ) : (
    <></>
  );
};
