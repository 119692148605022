import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMetricsView } from "../../reportApi";
import { MetricsView } from "../../types/dashboardTypes";
import { Loadable } from "../../types/util";

export interface MetricsViewData {
  metricsView: MetricsView;
}

export type MetricsViewId = string;

export interface MetricsViewState {
  metricsViewId: MetricsViewId;
  metricsViewState: Loadable<MetricsViewData>;
}

const initialState: MetricsViewState = {
  metricsViewId: "",
  metricsViewState: {
    loading: true,
  },
};

export const metricsViewSlice = createSlice({
  name: "metricsView",
  initialState,
  reducers: {
    setMetricsViewId(state, action: PayloadAction<string>) {
      state.metricsViewId = action.payload;
      state.metricsViewState.data = undefined;
    },
    setMetricsViewLoading(state, action: PayloadAction<boolean>) {
      state.metricsViewState.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.metricsViewState.error = action.payload;
    },
    setMetricsView(state, action: PayloadAction<MetricsView>) {
      state.metricsViewState.data = { metricsView: action.payload };
    },
  },
});

/**
 * initMetricsViewThunk - fetches a MetricsView by ID, sets it in state, then triggers API requests to populate the
 * data for each of the widgets in the view.
 */
export const initMetricsViewThunk = createAsyncThunk<
  { metricsViewResp: MetricsView }, // Return type on success
  { metricsViewId: string; customerId: string }, // Param
  { rejectValue: string } // Rejected action payload
>("metricsView/initMetricsView", async (params, { dispatch, rejectWithValue }) => {
  const { metricsViewId, customerId } = params;

  dispatch(metricsViewSlice.actions.setMetricsViewId(metricsViewId));
  dispatch(metricsViewSlice.actions.setMetricsViewLoading(true));

  // load the metrics view
  try {
    const metricsViewResp = await getMetricsView(customerId, metricsViewId);

    dispatch(metricsViewSlice.actions.setMetricsView(metricsViewResp));
    dispatch(metricsViewSlice.actions.setMetricsViewLoading(false));
    return { metricsViewResp };
  } catch (err) {
    console.error("initMetricsViewThunk error:", err);
    return rejectWithValue("Error fetching metrics view");
  }
});

/* ------------------------------------------------------------------
 * Export the slice's actions and reducer
 * ------------------------------------------------------------------ */
export const { setError } = metricsViewSlice.actions;

export default metricsViewSlice.reducer;
