import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getOrderedTaxonomyNodes,
  getSearchSemanticMatchedTaxonomyNodesResults,
  getTaxonomy,
  getTaxonomyOverviewMetadata,
  getView,
} from "../../reportApi";
import { isAPIError, TaxonomyNodeFilters } from "../../indexTypes";
import {
  CustomerId,
  NodeVisibilityReason,
  Taxonomy,
  TaxonomyLeafNodeMetadata,
  TaxonomyNodeId,
  TaxonomyNodeItem,
  TaxonomyTimeseriesMetadata,
  View,
  VisibleTaxonomyNodeItem,
} from "../../types/dashboardTypes";
import { ParsedExpression } from "../../types/expressionsDslTypes";
import { Loadable } from "../../types/util";



/* ------------------------------------------------------------------
 * Type Definitions
 * ------------------------------------------------------------------ */

/**
 * Redux structure for the "settings" portion of the dashboard view:
 * e.g. dates, filters, grouping, etc.
 */
export interface DashboardViewSettings {
  startDate: string;
  endDate: string;
  granularity: string;
  issuesGrouped: boolean;
  submittedIssueSearchInput: string;
  filters?: ParsedExpression;
  taxonomyNodeFilters?: TaxonomyNodeFilters;
  metadataAnalysisChoice?: string;
}

/**
 * The "view data" portion holds everything we need:
 * - The "View" itself (from the server)
 * - The settings
 * - Loadable containers for taxonomy, timeseries, node list, etc.
 */
export interface DashboardViewData {
  view: View;
  settings: DashboardViewSettings;
  taxonomy: Loadable<Taxonomy>;
  taxonomyTimeseries: Loadable<TaxonomyTimeseriesMetadata>;
  leafNodeMetadata: Loadable<TaxonomyLeafNodeMetadata[]>;
  semanticMatchedIds: Loadable<string[]>;
}

/**
 * The top-level dashboard state in the Redux store.
 */
export interface DashboardState {
  viewId: string;
  viewState: Loadable<DashboardViewData>;
}

/* ------------------------------------------------------------------
 * Helper Functions
 * ------------------------------------------------------------------ */

/**
 * Recursively gather all leaf node IDs.
 */
function getLeafNodeIds(nodes: TaxonomyNodeItem[]): string[] {
  const leafNodeIds: string[] = [];
  nodes.forEach((node: TaxonomyNodeItem) => {
    if (node.children.length > 0) {
      leafNodeIds.push(...getLeafNodeIds(node.children));
    } else {
      leafNodeIds.push(node.id);
    }
  });
  return leafNodeIds;
}

/* ------------------------------------------------------------------
 * Selectors
 * ------------------------------------------------------------------ */

const selectSearchValue = (state: { dashboard: DashboardState }) =>
  state.dashboard.viewState.data?.settings.submittedIssueSearchInput;
const selectSemanticMatchedIds = (state: { dashboard: DashboardState }) =>
  state.dashboard.viewState.data?.semanticMatchedIds.data;
const selectTaxonomyNodeFilters = (state: { dashboard: DashboardState }) =>
  state.dashboard.viewState.data?.settings.taxonomyNodeFilters;
const selectIssuesGrouped = (state: { dashboard: DashboardState }) =>
  state.dashboard.viewState.data?.settings.issuesGrouped;
const selectTaxonomy = (state: { dashboard: DashboardState }) =>
  state.dashboard.viewState.data?.taxonomy;
const selectTaxonomyLeafNodeMetadata = (state: { dashboard: DashboardState }) =>
  state.dashboard.viewState.data?.leafNodeMetadata || { loading: true };

export const selectVisibleTaxonomyNodeIds = createSelector(
  [
    selectSearchValue,
    selectSemanticMatchedIds,
    selectTaxonomyNodeFilters,
    selectIssuesGrouped,
    selectTaxonomy,
    selectTaxonomyLeafNodeMetadata,
  ],
  (
    searchValue,
    semanticMatchedIds,
    taxonomyNodeFilters,
    issuesGrouped,
    taxonomy,
    leafNodeMetadata
  ): Map<TaxonomyNodeId, NodeVisibilityReason> => {
    if (!taxonomy?.data?.rootNodes || !leafNodeMetadata.data) {
      // if no taxonomy, no visible nodes
      // leafNodeMetadata is grabbed from the server, so it should always be there if it's not we're not
      // done loading yet
      return new Map<TaxonomyNodeId, NodeVisibilityReason>();
    }
    const semanticMatchedIdsSet = new Set(semanticMatchedIds);

    // Check sentiment
    const matchSentimentPred = (node: TaxonomyNodeItem) => {
      if (node.children.length || !taxonomyNodeFilters?.sentiment.length) {
        return true;
      }
      const matchedLeaf = leafNodeMetadata.data?.find(
        (leaf: TaxonomyLeafNodeMetadata) => leaf.taxonomyNodeId === node.id
      );
      if (!matchedLeaf) return true;
      return taxonomyNodeFilters.sentiment.includes(matchedLeaf.sentiment);
    };

    const matchNodePred = (node: TaxonomyNodeItem): NodeVisibilityReason | null => {
      if (!issuesGrouped && node.children.length > 0) {
        return null; // If the issues aren't grouped, we're only matching L3 nodes.
      }

      if (!matchSentimentPred(node)) {
        return null; // If the sentiment doesn't match, we're definitely not showing it.
      }

      if (!searchValue) {
        return NodeVisibilityReason.NO_SEARCH_VALUE_SET;
      }

      if (searchValue && searchValue.trim().length > 0) {
        const lower = searchValue.trim().toLowerCase();
        const nameIncludes = node.resolvedName.toLowerCase().includes(lower);
        if (nameIncludes) {
          return NodeVisibilityReason.SEARCH_EXACT_MATCH;
        } else if (semanticMatchedIdsSet.has(node.id)) {
          return NodeVisibilityReason.SEARCH_SEMANTIC_MATCH;
        }
      }

      return null;
    };

    const _collectMatchingNodeIds = (
      nodes: TaxonomyNodeItem[],
      pred: (node: TaxonomyNodeItem) => NodeVisibilityReason | null,
      res: Map<TaxonomyNodeId, NodeVisibilityReason>,
      grouped_ancestor_already_matched = false
    ): boolean => {
      let foundMatch = false;
      for (const curr of nodes) {
        // Check if there's a match, and if so, track the reason. Prefer exact or semantic match reasons over simply
        // matching based on being a descendent of a matched node, because we want to be able to visually present
        // users with the most useful information on why some nodes are of *particular* interest.
        const currNodeMatchReason: NodeVisibilityReason | null =
          pred(curr) ??
          (grouped_ancestor_already_matched && matchSentimentPred(curr)
            ? NodeVisibilityReason.IS_MATCHED_NODE_DESCENDENT
            : null);

        if (currNodeMatchReason) {
          foundMatch = true;
          res.set(curr.id, currNodeMatchReason);
          if (issuesGrouped) {
            // If issues are grouped then we need to mark all children of the matching node visible
            // unless they're excluded by the sentiment filter.
            _collectMatchingNodeIds(
              curr.children,
              pred,
              res,
              true // Already matched the ancestor of anything from this point on.
            );
          }
        } else {
          const childrenMatched = _collectMatchingNodeIds(curr.children, pred, res);
          if (childrenMatched) {
            foundMatch = true;
            if (issuesGrouped) {
              // If the current node didn't match, but issues are grouped and some of the curr node's children
              // matched then we need the parent to be visible.
              res.set(curr.id, NodeVisibilityReason.IS_MATCHED_NODE_ANCESTOR);
            }
          }
        }
      }
      return foundMatch;
    };

    const visibleNodes = new Map<TaxonomyNodeId, NodeVisibilityReason>();

    _collectMatchingNodeIds(taxonomy.data.rootNodes, matchNodePred, visibleNodes);

    return visibleNodes;
  }
);

export const selectVisibleTaxonomyTree = createSelector(
  [
    selectTaxonomy,
    selectIssuesGrouped,
    selectVisibleTaxonomyNodeIds,
    selectTaxonomyLeafNodeMetadata,
    selectSemanticMatchedIds,
  ],
  (
    taxonomy,
    issuesGrouped,
    visibleTaxonomyNodeIdsMap,
    leafNodeMetadata,
    semanticMatchedIds,
  ): Loadable<VisibleTaxonomyNodeItem[]> => {
    if (!taxonomy?.data || !leafNodeMetadata.data) {
      return {
        loading: true,
      };
    }
    const nodeLookup = (() => {
      const nodeLookup = new Map<TaxonomyNodeId, TaxonomyNodeItem>();
      const addNodeToLookup = (node: TaxonomyNodeItem) => {
        nodeLookup.set(node.id, node);
        node.children.forEach(child => addNodeToLookup(child));
      };
      taxonomy.data.rootNodes.forEach(node => addNodeToLookup(node));
      return nodeLookup;
    })();

    const nodeCounts = (() => {
      const nodeCounts = new Map<TaxonomyNodeId, number>();
      leafNodeMetadata.data.forEach(leaf => {
        nodeCounts.set(leaf.taxonomyNodeId, leaf.conversationCount);
      });

      const resolveAndSaveCount = (node: TaxonomyNodeItem): number => {
        let resolvedCount;
        if (node.children.length) {
          resolvedCount = node.children.reduce((acc, child) => acc + resolveAndSaveCount(child), 0);
        } else {
          // a missing node count means there are no conversations for these filters for this node
          resolvedCount = nodeCounts.get(node.id) || 0;
        }
        nodeCounts.set(node.id, resolvedCount);
        return resolvedCount;
      };

      taxonomy.data.rootNodes.forEach((node: TaxonomyNodeItem) => {
        resolveAndSaveCount(node);
      });

      return nodeCounts;
    })();

    const nodes = (() => {
      if (issuesGrouped) {
        // deep copy the taxonomy tree
        const rootNodes = JSON.parse(JSON.stringify(taxonomy.data.rootNodes));

        const sortInPlace = (nodes: TaxonomyNodeItem[]) => {
          nodes.sort((a, b) => (nodeCounts.get(b.id) || 0) - (nodeCounts.get(a.id) || 0));
          nodes.forEach(node => {
            if (node.children.length) {
              sortInPlace(node.children);
            }
          });
        };

        const filterOutHiddenNodes = (nodes: TaxonomyNodeItem[]) => {
          return nodes.filter(node => {
            if (visibleTaxonomyNodeIdsMap.has(node.id)) {
              const hadChildren = !!node.children.length;
              node.children = filterOutHiddenNodes(node.children);

              return (hadChildren && node.children.length > 0) || !hadChildren;
            }
            return false;
          });
        };

        sortInPlace(rootNodes);
        return filterOutHiddenNodes(rootNodes);
      } else {
        // return the visible leaf nodes grouped by: exact, semantic, others
        const visibleLeafNodes = leafNodeMetadata.data.filter(
          leaf => visibleTaxonomyNodeIdsMap.has(leaf.taxonomyNodeId)
        )

        const exactMatches: TaxonomyLeafNodeMetadata[] = [];
        const semanticMatches: TaxonomyLeafNodeMetadata[] = [];
        const others: TaxonomyLeafNodeMetadata[] = [];

        for (const leaf of visibleLeafNodes) {
          const reason = visibleTaxonomyNodeIdsMap.get(leaf.taxonomyNodeId);
          if (reason === NodeVisibilityReason.SEARCH_EXACT_MATCH) {
            exactMatches.push(leaf);
          } else if (reason === NodeVisibilityReason.SEARCH_SEMANTIC_MATCH) {
            semanticMatches.push(leaf);
          } else {
            others.push(leaf);
          }
        }

        exactMatches.sort(
          (a, b) => b.conversationCount - a.conversationCount
        );

        semanticMatches.sort((a, b) => {
          const aIdx = semanticMatchedIds?.indexOf(a.taxonomyNodeId) ?? -1;
          const bIdx = semanticMatchedIds?.indexOf(b.taxonomyNodeId) ?? -1;
          return aIdx - bIdx;
        });

        const finalLeafNodes = [
          ...exactMatches,
          ...semanticMatches,
          ...others
        ];

        return finalLeafNodes.map(leaf => {
          const node = nodeLookup.get(leaf.taxonomyNodeId);
          if (!node) {
            throw new Error("Node not found for leaf node");
          }
          return node;
        });
      }
    })();

    const convertToVisibleTaxonomyNodeItem = (node: TaxonomyNodeItem): VisibleTaxonomyNodeItem => {
      const count = nodeCounts.get(node.id) || 0;
      const visibilityReason = visibleTaxonomyNodeIdsMap.get(node.id);
      if (!visibilityReason) {
        throw new Error("Node should be visible");
      }

      return {
        id: node.id,
        resolvedName: node.resolvedName,
        children: node.children.map(convertToVisibleTaxonomyNodeItem),
        count,
        visibilityReason,
      };
    };

    return {
      loading: leafNodeMetadata.loading || taxonomy.loading,
      data: nodes.map(convertToVisibleTaxonomyNodeItem),
    };
  }
);

/* ------------------------------------------------------------------
 * Initial State
 * ------------------------------------------------------------------ */
const initialState: DashboardState = {
  viewId: "",
  viewState: {
    loading: true,
  },
};

/* ------------------------------------------------------------------
 * The Slice
 * ------------------------------------------------------------------ */
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // Top-level loading & error
    setViewLoading(state, action: PayloadAction<boolean>) {
      state.viewState.loading = action.payload;
      state.viewState.error = undefined;
      if (action.payload) {
        state.viewState.error = undefined;
      }
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.viewState.error = action.payload;
    },

    // Minimal taxonomy timeseries & node list
    setTaxonomyLoading(state, action: PayloadAction<boolean>) {
      const taxonomy = state.viewState.data?.taxonomy;
      if (taxonomy) {
        taxonomy.loading = action.payload;
        if (action.payload) {
          taxonomy.error = undefined;
        }
      }
    },
    setTaxonomy(state, action: PayloadAction<Taxonomy>) {
      const taxonomy = state.viewState.data?.taxonomy;
      if (taxonomy) {
        taxonomy.data = action.payload;
      }
    },

    setTaxonomyTimeseriesLoading(state, action: PayloadAction<boolean>) {
      const timeseries = state.viewState.data?.taxonomyTimeseries;
      if (timeseries) {
        timeseries.loading = action.payload;
        if (action.payload) {
          timeseries.error = undefined;
        }
      }
    },
    setTaxonomyTimeseries(state, action: PayloadAction<TaxonomyTimeseriesMetadata>) {
      const timeseries = state.viewState.data?.taxonomyTimeseries;
      if (timeseries) {
        timeseries.data = action.payload;
      }
    },

    setTaxonomyLeafNodeMetadata(state, action: PayloadAction<TaxonomyLeafNodeMetadata[]>) {
      const leafNodes = state.viewState.data?.leafNodeMetadata;
      if (leafNodes) {
        leafNodes.data = action.payload;
      }
    },

    setTaxonomyLeafNodeMetadataLoading(state, action: PayloadAction<boolean>) {
      const leafNodes = state.viewState.data?.leafNodeMetadata;
      if (leafNodes) {
        leafNodes.loading = action.payload;
        if (action.payload) {
          leafNodes.error = undefined;
        }
      }
    },

    // setView, setViewId, updateSettings
    setView(state, action: PayloadAction<View>) {
      const getStartAndEndDates = (
        viewStartDate: string | undefined,
        numDays: number | undefined = 30,
        lastClassifiedConversationTimestamp: string | undefined
      ) => {
        const startDate = viewStartDate
          ? moment(viewStartDate)
          : moment(lastClassifiedConversationTimestamp).subtract(numDays, "day");
        let endDate = viewStartDate
          ? moment(viewStartDate).add(numDays, "days")
          : moment(lastClassifiedConversationTimestamp).add(1, "day");
        if (endDate.isAfter(moment(lastClassifiedConversationTimestamp))) {
          endDate = moment(lastClassifiedConversationTimestamp).add(1, "day");
        }
        if (endDate.isAfter(moment())) {
          endDate = moment();
        }
        return [startDate, endDate];
      };
      // If the server-provided view has default date range, set it
      const vs = action.payload.settings;
      const defaultPeriodLength = vs.defaultPeriodLength ?? 30;
      const defaultStartDate = vs.defaultStartDate;
      const [startDateMoment, endDateMoment] = getStartAndEndDates(
        defaultStartDate,
        defaultPeriodLength,
        action.payload.lastClassifiedConversationTimestamp
      );
      const startDate = startDateMoment.format("YYYY-MM-DD");
      const endDate = endDateMoment.format("YYYY-MM-DD");

      const defaultMetadata = Object.keys(vs.metadataAnalysisOptions).find(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        k => vs.metadataAnalysisOptions[k]?.is_default
      );

      state.viewState.data = {
        view: action.payload,
        settings: {
          startDate,
          endDate,
          granularity: "day",
          issuesGrouped: true,
          metadataAnalysisChoice: defaultMetadata,

          submittedIssueSearchInput: "",
        },
        taxonomy: { loading: true },
        taxonomyTimeseries: { loading: true },
        leafNodeMetadata: { loading: true },
        semanticMatchedIds: { loading: false },
      };
    },

    setViewId(state, action: PayloadAction<string>) {
      state.viewId = action.payload;
      state.viewState.data = undefined;
    },

    updateSettings(state, action: PayloadAction<Partial<DashboardViewSettings>>) {
      const newSettings = action.payload;
      if (!state.viewState.data) return;
      const current = state.viewState.data.settings;
      Object.assign(current, newSettings);
    },

    setSemanticMatchedIds(state, action: PayloadAction<Loadable<string[]>>) {
      if (!state.viewState.data) return;
      state.viewState.data.semanticMatchedIds = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .addCase(initViewThunk.rejected, (state, action) => {
        state.viewState.loading = false;
        state.viewState.error = (action.payload as string) || "Error fetching view";
      })
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .addCase(updateViewSettingsThunk.rejected, (state, action) => {
        state.viewState.error = (action.payload as string) || "Error updating settings";
      })
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .addCase(searchTaxonomyNodesThunk.rejected, (state, action) => {
        state.viewState.error = (action.payload as string) || "Error searching taxonomy";
      });
  },
});

/* ------------------------------------------------------------------
 * Thunks
 * ------------------------------------------------------------------ */

/**
 *  refreshTaxonomyDataThunk - fetch taxonomy, timeseries,
 *    and ordered leaf nodes in one go.
 */
export const refreshTaxonomyDataThunk = createAsyncThunk<
  { timeseries: TaxonomyTimeseriesMetadata }, // Return type
  CustomerId, // Param = customerId
  { rejectValue: string }
>("dashboard/refreshTaxonomyData", async (customerId, { dispatch, getState, rejectWithValue }) => {
  const state = getState() as { dashboard: DashboardState };
  const data = state.dashboard.viewState.data;
  if (!data?.view) {
    return rejectWithValue("No view set");
  }
  if (!data.taxonomy.data) {
    return rejectWithValue("No taxonomy set");
  }

  try {
    const { taxonomyId, id: viewId } = data.view;
    const { startDate, endDate, granularity, filters, taxonomyNodeFilters } = data.settings;

    // Fetch taxonomy + timeseries
    dispatch(dashboardSlice.actions.setTaxonomyLeafNodeMetadataLoading(true));
    dispatch(dashboardSlice.actions.setTaxonomyTimeseriesLoading(true));
    const timeseriesResp = await getTaxonomyOverviewMetadata(
      taxonomyId,
      viewId,
      customerId,
      startDate,
      endDate,
      granularity,
      filters,
      taxonomyNodeFilters
    );
    if (isAPIError(timeseriesResp)) {
      return rejectWithValue("Error fetching taxonomy timeseries data");
    }

    // Update Redux
    dispatch(dashboardSlice.actions.setTaxonomyTimeseries(timeseriesResp));
    dispatch(dashboardSlice.actions.setTaxonomyTimeseriesLoading(false));

    // gather leaf node IDs
    const leafNodeIds = getLeafNodeIds(data.taxonomy.data.rootNodes);
    const context = { granularity, startDate, endDate, filters, taxonomyNodeFilters };
    const orderedLeafNodes = await getOrderedTaxonomyNodes(
      taxonomyId,
      viewId,
      leafNodeIds,
      customerId,
      context
    );

    dispatch(dashboardSlice.actions.setTaxonomyLeafNodeMetadata(orderedLeafNodes));
    dispatch(dashboardSlice.actions.setTaxonomyLeafNodeMetadataLoading(false));

    return { timeseries: timeseriesResp };
  } catch (err) {
    console.error("refreshTaxonomyDataThunk error:", err);
    return rejectWithValue("Error fetching taxonomy");
  }
});

/**
 *    initViewThunk - fetches a View by ID, sets it in state,
 *    then triggers a taxonomy refresh.
 */
export const initViewThunk = createAsyncThunk<
  { viewResp: View }, // Return type on success
  { viewId: string; customerId: string }, // Param
  { rejectValue: string } // Rejected action payload
>("dashboard/initView", async (params, { dispatch, rejectWithValue }) => {
  const { viewId, customerId } = params;
  dispatch(dashboardSlice.actions.setViewId(viewId));
  dispatch(dashboardSlice.actions.setViewLoading(true));
  try {
    const viewResp = await getView(viewId, customerId);
    if (isAPIError(viewResp)) {
      return rejectWithValue("Error fetching view");
    }
    dispatch(dashboardSlice.actions.setView(viewResp));
    dispatch(dashboardSlice.actions.setViewLoading(false));

    // load the taxonomy
    dispatch(dashboardSlice.actions.setTaxonomyLoading(true));
    const taxonomyResp = await getTaxonomy(viewResp.taxonomyId, customerId);
    if (isAPIError(taxonomyResp)) {
      return rejectWithValue("Error fetching taxonomy");
    }

    dispatch(dashboardSlice.actions.setTaxonomy(taxonomyResp));
    dispatch(dashboardSlice.actions.setTaxonomyLoading(false));
    // after the view and taxonomy is set, refresh taxonomy data
    await dispatch(refreshTaxonomyDataThunk(customerId));
    return { viewResp };
  } catch (err) {
    console.error("initViewThunk error:", err);
    return rejectWithValue("Error fetching view");
  }
});

/**
 *    updateViewSettingsThunk - merges new settings into the store,
 *    then triggers a data refresh if certain fields changed.
 */
export const updateViewSettingsThunk = createAsyncThunk<
  void, // Nothing returned
  { newSettings: Partial<DashboardViewSettings>; customerId: string }, // Param
  { rejectValue: string }
>(
  "dashboard/updateViewSettings",
  async ({ newSettings, customerId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(dashboardSlice.actions.updateSettings(newSettings));
      await dispatch(refreshTaxonomyDataThunk(customerId));
    } catch (err) {
      console.error("updateViewSettingsThunk error:", err);
      return rejectWithValue("Error updating settings");
    }
  }
);

/**
 *    searchTaxonomyNodesThunk - local searching + semantic matching.
 *    Updates visibleTaxonomyNodeIds in settings.
 */
export const searchTaxonomyNodesThunk = createAsyncThunk<
  void, // nothing returned
  { customerId: string; searchValue?: string },
  { rejectValue: string }
>("dashboard/searchTaxonomyNodes", async ({ customerId, searchValue }, { dispatch, getState }) => {
  try {
    const state = getState() as { dashboard: DashboardState };
    const data = state.dashboard.viewState.data;
    if (!data?.view) {
      dispatch(dashboardSlice.actions.setError("No view set for searching."));
      return;
    }

    const taxonomyData = data.taxonomy.data;
    if (!taxonomyData || !taxonomyData.id) {
      dispatch(dashboardSlice.actions.setError("No taxonomy data for searching."));
      return;
    }

    let semanticMatchedIds: TaxonomyNodeId[] = [];
    if (searchValue && searchValue.trim().length > 0) {
      dispatch(
        dashboardSlice.actions.setSemanticMatchedIds({
          loading: true,
        })
      );

      const resp = await getSearchSemanticMatchedTaxonomyNodesResults(
        customerId,
        taxonomyData.id,
        searchValue
      );
      semanticMatchedIds = resp.matchingTaxonomyNodeIds;
    }

    // store new visible set + store searchValue in settings
    dispatch(
      dashboardSlice.actions.setSemanticMatchedIds({
        data: semanticMatchedIds,
        loading: false,
      })
    );
    dispatch(
      dashboardSlice.actions.updateSettings({
        submittedIssueSearchInput: searchValue,
      })
    );
  } catch (err) {
    console.error("searchTaxonomyNodesThunk error:", err);
    dispatch(dashboardSlice.actions.setError("Error searching taxonomy"));
  }
});

/* ------------------------------------------------------------------
 * Export the slice's actions and reducer
 * ------------------------------------------------------------------ */
export const { setError } = dashboardSlice.actions;

export default dashboardSlice.reducer;
