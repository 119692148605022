import { CloseOutlined, CopyOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
  notification,
} from "antd";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { InputStatus } from "antd/lib/_util/statusUtils";
import { TablePaginationConfig } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import React, { useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useCustomer } from "../../hooks";
import { CategoryList, Sentiment, SentimentList, isAPIError } from "../../indexTypes";
import { createTitles, getTitlesFile, syncTitles, updateTitlesFile } from "../../reportApi";
import { ClusterInfo, SuperclusterInfo, Titles } from "../../reports";
import { displayName, isDefined } from "../../utils";
import "./TitlesPage.less";

const { Option } = Select;

export const TitlesPage = ({
  titlesFileId,
  allTitlesFiles = [],
  reload,
}: {
  titlesFileId: string;
  allTitlesFiles: {[id: string]: string}[];
  reload?: () => Promise<void>;
}) => {
  const { customer } = useCustomer();
  const [titles, setTitles] = useState<Titles>();
  const [saving, setSaving] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [chosenSet, setChosenSet] = useState<string>();
  const [showSuperClusterTable, setShowSuperClusterTable] = useState<boolean>(true);
  const [titlesLoading, setTitlesLoading] = useState<boolean>(true);

  const [newTitlesName, setNewTitlesName] = useState<string>();
  const [titlesFileToCopy, setTitlesFileToCopy] = useState<string | undefined>();

  const loadTitles = useCallback(
    async (controller: AbortController) => {
      setTitlesLoading(true);
      try {
        const titlesFile = await getTitlesFile(controller.signal, customer.id,  titlesFileId);
        setTitles(titlesFile);
      } finally {
        setTitlesLoading(false);
      }
      return () => {
        controller.abort();
      };
    },
/* eslint-disable-next-line react-hooks/exhaustive-deps */
    [titlesFileId]
  );

  useEffect(() => {
    if (!chosenSet) {
      setChosenSet(customer.index.defaultReportSet);
    } else {
      const controller = new AbortController();
      loadTitles(controller);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, chosenSet, titlesFileId]);

  const save = async () => {
    setSaving(true);
    try {
      if (titles) {
        await updateTitlesFile(customer.id, titlesFileId, titles);
        notification.open({
          message: `Saved ${displayName(chosenSet)}!`,
          duration: 1,
        });
        window.location.reload();
      }
    } finally {
      setSaving(false);
    }
  };

  const addSupercluster = () => {
    if (titles) {
      const newid = (Math.random() + 1).toString(36).substring(2);
      setTitles({
        ...titles,
        ...{ superclusters: { ...titles.superclusters, [newid]: { id: newid } } },
      });
    }
  };

  const onSyncTitles = () => {
    const f = async () => {
      const controller = new AbortController();
      try {
        setSyncing(true);
        await syncTitles(customer.id, titlesFileId);
        await loadTitles(controller);
        notification.success({
          message: "Titles synced!",
        });
      } catch (e) {
        if (!(e instanceof Error)) return;
        notification.error({
          message: "Error syncing titles",
          description: e.message,
        });
      } finally {
        setSyncing(false);
      }
    };

    f();
  };

  async function addNewTitles() {
    if (!isDefined(newTitlesName)) return;
    try {
      const copyFrom = (titlesFileToCopy && titlesFileToCopy.length) ? titlesFileToCopy : undefined;
      const resp = await createTitles(customer.id, newTitlesName, copyFrom);
      if (isAPIError(resp)) {
        console.error(resp.error, resp.description);
        notification.error({
          className: "selectable",
          message: `Failure creating titles! ${resp.description}`,
          duration: 10,
        });
        return;
      }
      if (reload) await reload();
      notification.open({
        message: `Titles created!`,
        duration: 5,
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: `Titles API failure! ${err}`,
        className: "selectable",
        duration: 10,
      });
    }
  }

  const renderSentiment = (value:Sentiment | "" | undefined, record:ClusterInfo) => {
    const sentimentValue = value !== "" ? value : undefined;

    const sentimentSelect = (
      <div>
        <Select
          className={`sentiment-dropdown ${sentimentValue}`}
          defaultValue={sentimentValue}
          onChange={val => {
            (record as ClusterInfo).sentiment = val;
          }}
          dropdownMatchSelectWidth={false}
        >
          <Option value="">-</Option>
          {SentimentList.map(sc => (
            <Option key={sc} value={sc}>
              {sc}
            </Option>
          ))}
        </Select>
      </div>
    );

    return sentimentSelect;
  }

  return (
    <div>
      <Row style={{ margin: "0 0 24px" }}>
        <Col span={14}>
          <Input.Group compact>
            <Input
              style={{ width: 350 }}
              placeholder="titles name"
              allowClear={true}
              value={newTitlesName}
              onChange={e => setNewTitlesName(e.target.value)}
            />
            <Select
              style={{ width: 200 }}
              showSearch
              placeholder="Copy from (optional):"
              onChange={(value) => setTitlesFileToCopy(value)}
              disabled={!newTitlesName || newTitlesName.length === 0}
              allowClear
              onClear={() => setTitlesFileToCopy(undefined)}
              options={allTitlesFiles.map((titlesData) => ({
                value: titlesData.id,
                label: titlesData.name,
              }))}
              value={titlesFileToCopy}
            />
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => addNewTitles()}
              disabled={!newTitlesName || newTitlesName.length === 0}
            >
              New
            </Button>
          </Input.Group>
        </Col>
      </Row>
      <Row style={{ margin: "0 0 24px" }}>
        <Col span={24}>
          <Space size="large">
            <Button type="primary" onClick={save} loading={saving} disabled={titlesLoading}>
              Save
            </Button>
            <Space size="middle">
              <span>Show Super Cluster Table:</span>
              <Switch checked={showSuperClusterTable} onChange={setShowSuperClusterTable} />
            </Space>
            <Button
              type="primary"
              onClick={onSyncTitles}
              loading={syncing}
              disabled={titlesLoading}
              icon={<SyncOutlined />}
            >
              Sync Titles
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="selectable">
        <Col span={24}>
          <Spin tip="Loading..." spinning={titlesLoading} style={{ width: "100%" }} />
          {showSuperClusterTable && !titlesLoading && (
/* eslint-disable-next-line @typescript-eslint/no-use-before-define */
            <SuperClusterTable
              saving={saving}
              titles={titles}
              addSupercluster={addSupercluster}
              renderSentiment={renderSentiment}
            />
          )}
          {titles && !titlesLoading && (
// eslint-disable-next-line @typescript-eslint/no-use-before-define
            <ClusterTable
              titles={titles}
              chosenSet={chosenSet}
              renderSentiment={renderSentiment}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const SuperClusterTable = ({
  saving,
  titles,
  addSupercluster,
  renderSentiment,
}: {
  saving: boolean;
  titles?: Titles;
  addSupercluster: () => void;
  renderSentiment: (value:Sentiment | "", record:ClusterInfo) => JSX.Element;
}) => {
  const defaultPerPage = 10;
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSizeOptions: ["10", "25", "50", "100"],
    showSizeChanger: true,
    pageSize: 10,
    current: 1,
    defaultPageSize: defaultPerPage,
  });

  function handleAddSupercluster() {
    setPagination({
      ...pagination,
      current: Math.ceil(
        (Object.keys(titles?.superclusters ?? []).length + 1) /
          (pagination.pageSize ?? defaultPerPage)
      ),
    });
    addSupercluster();
  }

  return (
    <>
      <Button type="primary" onClick={handleAddSupercluster} disabled={saving}>
        Add Supercluster
      </Button>
      <Table
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        dataSource={titles ? Object.values(titles.superclusters ?? {}) : []}
        pagination={pagination}
        size="small"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
        onChange={(page, _pageSize) => setPagination({ ...page })}
        rowKey={record => record.id}
      >
        <Column
          title="#"
          key="index"
          dataIndex="index"
          width={5}
          render={(_val, _record, index) => `${index + 1}`}
        />
        <Column title="Super Cluster" key="supercluster" dataIndex="id" width={120} />
        <Column
          title="Sentiment"
          key="sentiment"
          dataIndex="sentiment"
          width={120}
          render={renderSentiment}
        />
        <Column
          title="Title"
          key="title"
          dataIndex="title"
          render={(val, record) => (
            <Input
              defaultValue={val}
              onChange={e => {
                (record as SuperclusterInfo).title = e.target.value;
              }}
            />
          )}
        />
        <Column
          title="Category"
          key="category"
          dataIndex="category"
          render={(val, record) => (
            <div>
              <Select
                style={{ width: 100 }}
                defaultValue={val}
                onChange={value => {
                  (record as ClusterInfo).category = value !== "" ? value : undefined;
                }}
                dropdownMatchSelectWidth={false}
              >
                <Option value="">-</Option>
                {CategoryList.map(category => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        />
        <Column
          title="Enabled"
          key="enabled"
          dataIndex="enabled"
          width={60}
          render={(val, record) => (
            <Switch
              defaultChecked={val ?? true}
              onChange={checked => ((record as SuperclusterInfo).enabled = checked)}
            />
          )}
        />
      </Table>
    </>
  );
};

const ClusterTable = ({
  titles,
  chosenSet,
  renderSentiment,
}: {
  titles: Titles;
  chosenSet?: string;
  renderSentiment: (value:Sentiment | "", record:ClusterInfo) => JSX.Element;
}) => {
  const [data, setData] = useState<{ [id: string]: ClusterInfo }>(titles.titles);
  const [tableKey, setTableKey] = useState<number>(0);

  useEffect(() => {
    setData(titles.titles);
  }, [titles, chosenSet]);

  const numEnabled = Object.values(titles.titles).filter(
    t => !("enabled" in t) || t.enabled == true
  ).length;
  const numTotal = Object.keys(titles.titles).length;

  // Updating the key forces a re-render of the titles table, which will reset all of the filters
  // https://github.com/ant-design/ant-design/issues/18001#issuecomment-1276676393
  const resetFilters = () => {
    setTableKey(tableKey => tableKey + 1);
  };

  const SuperClusterSelect = () => (
    <Select
      placeholder="Supercluster Filter"
      dropdownMatchSelectWidth={false}
      onChange={value => {
        if (value === "") {
          setData(titles.titles);
        } else if (value === "WITH_SUPERCLUSTER") {
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const filteredData = Object.entries(titles.titles!)
            .filter(cluster => !!cluster[1].supercluster)
            .reduce<{ [id: string]: ClusterInfo }>((accum, [k, v]) => {
              // eslint-disable-next-line no-param-reassign
              accum[k] = v;
              return accum;
            }, {});
          setData(filteredData);
        } else {
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const filteredData = Object.entries(titles.titles!)
            .filter(cluster => cluster[1].supercluster === value)
            .reduce<{ [id: string]: ClusterInfo }>((accum, [k, v]) => {
              // eslint-disable-next-line no-param-reassign
              accum[k] = v;
              return accum;
            }, {});
          setData(filteredData);
        }
      }}
      filterOption={(input, option) =>
/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      showSearch
    >
      <Option key="blank" value="">
        All
      </Option>
      <Option key="with_supercluster" value="WITH_SUPERCLUSTER">
        HAS A SUPERCLUSTER
      </Option>
{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {titles &&
/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        titles.superclusters &&
        Object.entries(titles.superclusters).map(superinfo => (
          <Option key={superinfo[0]} value={superinfo[0]}>
            {superinfo[1].title}
          </Option>
        ))}
    </Select>
  );

  const ClusterSelect = () => {
    const options = Object.values(
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      titles.titles ?? []).map(t => ({ label: t.id, value: t.id })
    );
    return (
      <Select
        allowClear
        onClear={() => {
          setData(titles.titles);
        }}
        style={{ width: "100%" }}
        options={options}
        placeholder="Cluster Filter"
        onChange={(value) => {
          value && setData({ [value]: titles.titles[value] });
        }}
        showSearch
        value={Object.keys(data).length === 1 ? Object.keys(data)[0] : undefined}
      />
    );
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%", zIndex: 10001 }}>
        <Space>
          <SuperClusterSelect />
          <ClusterSelect />
          <Typography.Text strong>
            {numEnabled} / {numTotal} enabled ({((numEnabled / numTotal) * 100).toFixed(1)}
            %)
          </Typography.Text>
          <Button type="primary" icon={<CloseOutlined />} onClick={() => resetFilters()}>
            Reset Filters
          </Button>
        </Space>
        <Table
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          dataSource={data ? Object.values(data).filter(c => !(c.deprecated ?? false)) : []}
          pagination={{ pageSizeOptions: ["10", "25", "50", "100"], showSizeChanger: true }}
          size="small"
          className="selectable titles-table"
          key={tableKey}
          rowKey={record => `${record.id}`}
        >
          <Column
            title="Super Cluster"
            key="superclusters"
            dataIndex="supercluster"
            width={80}
            render={(val, record) => (
              <Select
                style={{ width: 120 }}
                defaultValue={val}
                onChange={value => {
                  (record as ClusterInfo).supercluster = value !== "" ? value : undefined;
                }}
                dropdownMatchSelectWidth={false}
              >
                <Option value="">-</Option>
{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {Object.values(titles.superclusters ?? []).map(sc => (
                  <Option key={sc.id + sc.title} value={sc.id}>
                    {sc.title ?? sc.id}
                  </Option>
                ))}
              </Select>
            )}
          />
          <Column
            title="Cluster"
            key="id"
            dataIndex="id"
            width={80}
          />
          <Column
            title="Sentiment"
            key="sent"
            dataIndex="sentiment"
            width={100}
            filters={[
              { text: "NONE", value: "NONE" },
              ...SentimentList.map(s => ({ text: s, value: s })),
            ]}
            onFilter={(value, record) => {
              if (value === "NONE") {
                return !(record as ClusterInfo).sentiment;
              }
              return (record as ClusterInfo).sentiment === value;
            }}
            filterMultiple={false}
            render={renderSentiment}
          />
          <Column
            title="Title"
            key="title"
            dataIndex="title"
            filters={[
              {
                text: "Titled",
                value: "TITLED",
              },
              {
                text: "Non-Titled",
                value: "NON_TITLED",
              },
            ]}
            filterMultiple={false}
            onFilter={(value, record) => {
              if (value === "TITLED") {
                return !!(record as ClusterInfo).title;
              }
              if (value === "NON_TITLED") {
                return !(record as ClusterInfo).title;
              }
              return false;
            }}
            render={(val, record) => (
              <Input
                defaultValue={val}
                onChange={e => {
                  (record as ClusterInfo).title = e.target.value;
                }}
                placeholder={(record as ClusterInfo).defaultTitle}
                spellCheck
              />
            )}
          />
          <Column
            title="Category"
            key="category"
            dataIndex="category"
            width={110}
            filters={[
              { text: "NONE", value: "none" },
              ...CategoryList.map(c => ({ text: c, value: c })),
            ]}
            filterMultiple={false}
            onFilter={(value, record) => {
              if (value === "none") {
                return !(record as ClusterInfo).category;
              } else {
                return (record as ClusterInfo).category === value;
              }
            }}
            render={(val, record) => (
              <div>
                <Select
                  style={{ width: 110 }}
                  defaultValue={val}
                  onChange={value => {
                    (record as ClusterInfo).category = value !== "" ? value : undefined;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  <Option value="">-</Option>
                  {CategoryList.map(category => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          />
          <Column
            title="Default"
            key="default"
            dataIndex="defaultTitle"
            width={40}
            render={(_, record) => (
              <CopyToClipboard text={(record as ClusterInfo).defaultTitle ?? ""}>
                <CopyOutlined />
              </CopyToClipboard>
            )}
          />
          <Column
            title="Emerging"
            key="emerging"
            dataIndex="emerging"
            width={60}
            render={(val, record) => (
              <Switch
                defaultChecked={val ?? false}
                onChange={checked => ((record as ClusterInfo).emerging = checked)}
              />
            )}
          />
          <Column
            title="Enabled"
            key="enabled"
            dataIndex="enabled"
            width={60}
            filters={[
              {
                text: "Enabled",
                value: "ENABLED",
              },
              {
                text: "Disabled",
                value: "DISABLED",
              },
            ]}
            filterMultiple={false}
            onFilter={(value, record) =>
              value === "ENABLED"
                ? !!(record as ClusterInfo).enabled
                : !(record as ClusterInfo).enabled
            }
            render={(val, record) => (
              <Switch
                defaultChecked={val ?? true}
                onChange={checked => ((record as ClusterInfo).enabled = checked)}
              />
            )}
          />
        </Table>
      </Space>
    </>
  );
};
