import { Tooltip } from "antd";
import React from "react";
import { ExperimentOutlined } from "@ant-design/icons";

export const HighlightedText = (
  { 
    text, 
    highlightedSubstring, 
    tooltip = undefined,
  }: {
    text: string, 
    highlightedSubstring?: string, 
    tooltip?: string,
  }
) => {
  if (!highlightedSubstring) {
    return (
      <>
        <Tooltip title={tooltip} placement="bottom">
          <span
            style={{
              textDecoration: 'underline',
              textDecorationStyle: 'dotted',
              textDecorationColor: '#465FC3',
              textDecorationThickness: '3px',
              textDecorationSkipInk: 'none',
              padding: '0 0px',
              borderRadius: '0px',
            }}
          >
            {text}
          </span>
          {tooltip && <ExperimentOutlined style={{ marginLeft: '5px' }} />}
        </Tooltip>
      </>
    );
  }
  if (!highlightedSubstring.trim()) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${highlightedSubstring})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <mark
            key={index}
            style={{
              backgroundColor: '#ffffb3',  // 70% lightened "yellow": https://mdigi.tools/lighten-color/#ffff00.
              padding: '0 0px',
              borderRadius: '0px',
            }}
          >
            <Tooltip title={tooltip} placement="bottom">
              {part}
            </Tooltip>
          </mark>
        ) : (
          part
        )
      )}
    </span>
  );
};
