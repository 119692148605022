import { Spin } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { initMetricsViewThunk } from "../../features/metricsView/metricsViewSlice";
import { useAppDispatch, useAppSelector, useCustomer } from "../../hooks";

const InnerMetricsView: React.FC<{ metricsViewId: string }> = ({ metricsViewId }) => {
  const dispatch = useAppDispatch();
  const { metricsViewState } = useAppSelector(state => state.metricsView);
  const { customer } = useCustomer();

  useEffect(() => {
    dispatch(initMetricsViewThunk({ metricsViewId, customerId: customer.id }));
  }, [customer.id, dispatch, metricsViewId]);

  return (
    <Spin style={{ width: "100%", height: "500px" }} spinning={metricsViewState.loading}>
      {metricsViewState.data?.metricsView && <div>Hi there. The ID is {metricsViewId}</div>}
    </Spin>
  );
};

export const MetricsView: React.FC = () => {
  const { metricsViewId } = useParams<{ metricsViewId: string }>();
  if (!metricsViewId) {
    console.error(
      "In metrics view page but no metrics view ID provided, this should not be possible"
    );
    return <div>Something went wrong, try refreshing the page or contact support</div>;
  }
  return <InnerMetricsView metricsViewId={metricsViewId} />;
};
