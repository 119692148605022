import { Divider, Layout, Space } from "antd";
import React, { useEffect, useState } from "react";

import logo from "../header_logo.png";
import { CustomerUIModel } from "../hooks";

export const CustomerImage = ({ customer }: { customer: CustomerUIModel }) => {
  const [src, setSrc] = useState<string>();
  useEffect(() => {
    setSrc(`https://static.spiralup.co/${customer.index.logo}`);
  }, [customer, setSrc]);

  return (
    <img
      id="customerlogo"
      src={src}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      alt={customer.index?.displayName}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      style={{ ...{ height: 26 }, ...customer.index?.logoStyle }}
    />
  );
};

export const BaseHeader = ({
  customer,
  children,
}: {
  customer?: CustomerUIModel;
  children?: React.ReactNode;
}) => (
  <Layout.Header
    style={{
      padding: "0 32px",
    }}
  >
    <div
      style={{
        float: "left",
        userSelect: "none",
        WebkitUserSelect: "none",
      }}
    >
      <a href="/">
        <Space size="small">
          <img src={logo} alt="" height="32px" style={{ marginRight: "-5px" }} />
          {customer && (
            <>
              <Divider type="vertical" style={{ height: "40px" }} />
              <CustomerImage customer={customer} />
            </>
          )}
        </Space>
      </a>
    </div>
    {children}
  </Layout.Header>
);
