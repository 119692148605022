import React from 'react';
import './TypingIndicator.less'; // Import the LESS file for the animation

const TypingIndicator: React.FC = () => {
  return (
    <div className="typing-indicator">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
  );
};

export default TypingIndicator;

